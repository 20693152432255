<script>
import Image                     from '../Objects/Image';
import ScrollRouter              from '../Components/ScrollRouter';
import ProjectDetail             from './ProjectDetail.vue';
import TheWorkOfThePeopleProject from '../Objects/Projects/TheWorkOfThePeopleProject';

export default {
	extends: ProjectDetail,
	data() {
		return new TheWorkOfThePeopleProject()
	},
}

</script>