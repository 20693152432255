import AnimationSuite             from "./AnimationSuite";
import HideProjectAnimation       from "../HideProjectAnimation";
import HideProjectHeaderAnimation from "../HideProjectHeaderAnimation";

export default class HideProjectAnimationSuite extends AnimationSuite {

    constructor(el) {
        super();
        this.animations = [
            new HideProjectAnimation(el, 0.75, 0.5),
            new HideProjectHeaderAnimation(el + " > header", 0.75, 0.5),
        ];
    }


}