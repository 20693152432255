import { TweenMax }    from "gsap";
import FromToAnimation from "./FromToAnimation";

export default class GrowRightAnimation extends FromToAnimation {
    getFrom() {
        return {
            scaleX   : 0,
            transformOrigin: "left",
            delay    : this.delay,
            ease     : Power4.easeOut
        }
    }

    getTo() {
        return {
            scaleX   : 1,
            delay    : this.delay,
            ease     : Power4.easeOut
        }
    }
}