import Required from "./Required";
import Email    from "./Email";
import Url      from "./Url";
import Phone    from "./Phone";

export default class Validator {

    constructor(form = {}, ruleSet = {}) {
        this.form = form;
        this.ruleSet = ruleSet;
        this.currentfield = '';
        this.parentfields = [];
        this.errors = {};
        this.ruleObjects = {
            url     : Url,
            email   : Email,
            required: Required,
            phone   : Phone,
        }
    }


    validateField(fieldName) {
        let errors;
        let ruleSet = this.ruleSet;

        this.currentfield = fieldName;

        if(fieldName.indexOf('.')) {
            let fieldNames = fieldName.split('.');
            for(let i = 0; i < fieldNames.length - 1; i++) {
                this.parentfields.push(fieldNames[i]);
                ruleSet = ruleSet[fieldNames[i]];
                this.currentfield = fieldNames[i + 1];
            }
        }

        errors = this.getErrorsInCurrentField(ruleSet);

        this.setErrors(errors);
        if(errors.length < 1) {
            this.removeErrorFromCurrentField();
        }

        return errors;
    }

    removeErrorFromCurrentField() {
        let errors = this.errors;
        for(let i in this.parentfields) {
            errors = errors[this.parentfields[i]];
        }

        if(errors === undefined) {
            return;
        }

        errors[this.currentfield] = [];
    }


    validate() {
        this.setErrorsInRuleSet(this.ruleSet);

        return this.errors;
    }

    setErrorsInRuleSet(ruleSet) {
        for(this.currentfield in ruleSet) {
            let errors = this.getErrorsInCurrentField(ruleSet);
            this.setErrors(errors);
        }
        this.parentfields.pop();
    }

    getErrorsInCurrentField(ruleSet) {
        let error;
        let errors = [];

        if(this.isParentField(ruleSet)) {
            this.parentfields.push(this.currentfield);
            this.setErrorsInRuleSet(ruleSet[this.currentfield]);
        }

        for(let ruleObjectKeyIndex in this.getFieldRules(ruleSet)) {
            error = this.callValidationMethod(ruleObjectKeyIndex, ruleSet);
            if(error !== undefined) {
                errors.push(error);
            }
        }

        return errors;
    }


    getError(field) {
        if(!this.fails()) {
            return;
        }

        let errors = this.errors;
        let error;

        if(field.indexOf('.')) {
            let fields = field.split('.');
            for(let i in fields) {
                errors = errors[fields[i]];
            }
            error = errors;
        }
        else {
            error = errors[field];
        }


        return error ? error[0] : null;
    }


    setErrors(errors) {
        if(this.errorsAreSet(errors)) {
            if(this.hasParentField()) {
                this.setErrorsWithParent(errors);
            }
            else {
                this.errors[this.currentfield] = errors;
            }
        }
    }


    setErrorsWithParent(newErrors) {
        let errors = this.errors;

        for(let i in this.parentfields) {
            let parent = this.parentfields[i];
            if(!errors[parent]) {
                errors[parent] = {};
            }
            errors = errors[parent];
        }
        errors[this.currentfield] = newErrors;
    }

    getFieldValue(fieldName) {
        if(this.hasParentField()) {
            return this.getChildFieldValue(fieldName);
        }

        return this.form[fieldName];
    }


    getChildFieldValue(fieldName) {
        let form = this.form;
        for(let parent in this.parentfields) {
            form = form[this.parentfields[parent]];
        }

        return form[fieldName];
    }


    getFieldRules(ruleSet) {
        return ruleSet[this.currentfield];
    }


    callValidationMethod(ruleObjectKeyIndex, ruleSet) {
        let ruleObjectKey = this.getFieldRules(ruleSet)[ruleObjectKeyIndex];
        return (
            new this.ruleObjects[ruleObjectKey](this.currentfield, this.getFieldValue(this.currentfield))
        ).validate()
    }


    hasParentField() {
        return this.parentfields.length > 0;
    }

    isParentField(ruleSet) {
        return !Array.isArray(ruleSet[this.currentfield]);
    }

    errorsAreSet(errors) {
        return errors !== undefined && errors.length > 0
    }


    fails() {
        return Object.keys(this.errors).length > 0;
    }

}
