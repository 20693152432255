<template>
	<svg version="1.1" class="icon-instagram" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 22 22" style="enable-background:new 0 0 22 22;" xml:space="preserve">
    <path class="st0" d="M15.5,22H6.5C2.9,22,0,19.1,0,15.5V6.5C0,2.9,2.9,0,6.5,0h9.1C19.1,0,22,2.9,22,6.5v9.1
        C22,19.1,19.1,22,15.5,22z M20,6.5C20,4,18,2,15.5,2H6.5C4,2,2,4,2,6.5v9.1C2,18,4,20,6.5,20h9.1c2.4,0,4.4-2,4.4-4.4V6.5z M17,6.5
        h-0.1c-0.7,0-1.2-0.6-1.2-1.2V5.1c0-0.7,0.6-1.2,1.2-1.2H17c0.7,0,1.2,0.6,1.2,1.2v0.1C18.2,5.9,17.6,6.5,17,6.5z M11,16.6
        c-3.1,0-5.6-2.5-5.6-5.6c0-3.1,2.5-5.6,5.6-5.6c3.1,0,5.6,2.5,5.6,5.6C16.6,14.1,14.1,16.6,11,16.6z M11,7.4C9,7.4,7.4,9,7.4,11
        c0,2,1.6,3.6,3.6,3.6s3.6-1.6,3.6-3.6C14.6,9,13,7.4,11,7.4z"/>
    </svg>
</template>

<script>
    export default {
		name: "icon-instagram",
        data() {
            return {
                
            }
        }
    }
</script>
