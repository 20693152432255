<template>
    <div>
        <work-list ref="workList" :projects="projects" />
        <div v-if="projects.selected > -1">
            <component :is="projects.getSelectedComponent()" :next-project="projects.getNextProject()"
            :previous-project="projects.getPreviousProject()" />
        </div>
    </div>    
</template>

<script>
import { TweenMax, TimelineLite } from "gsap"
import { setWorkItemWidth, setWorkItemHeight } from "../helpers";
import Coordinates                             from "../Objects/Coordinates";
import Animation                               from "../Objects/Animations/Animation";
import WorkList                                from "../Components/WorkList";
import BaseView                                from "../Components/BaseView";
import { projects, beforeWork }                from "../Store.js";
import { altNav }                              from "../Store";
import HeaderInAnimationSuite                  from "../Objects/Animations/Suites/HeaderInAnimationSuite";
import StaggerUpAnimation                      from "../Objects/Animations/StaggerUpAnimation";
import { animateHeaderIn, animateWorkItemsIn } from "../Objects/Animations/animation-helpers";

export default {
    extends: BaseView,
    components: {
        WorkList
    },

    data() {
        return {
            wrapper   : "work",
            message        : "Work",
            backgroundImage: '',
            projects       : projects,
            root: document.documentElement,
            body: document.body,
        }
    },

    computed: {
    
        workItemWidth: function() {
            return setWorkItemWidth();
        },

        workItemHeight: function() {
            return setWorkItemHeight();
        },

        spacerWidth: function() {
            return setWorkItemWidth() / 2;
        }
    },

    beforeDestroy() {
        window.recentProject.latest = null;
    },

    mounted() {
        this.$emit("routeChanged", '', this.wrapper);
        this.projects.selectFromRouteName(this.$route.name);
        this.setupWorkView();
        this.setBackButtonData(beforeWork.route, "Back");
    },

    watch: {
        '$route.path': function() {
            this.projects.selectFromRouteName(this.$route.name);
        },
    },

    methods: {
        setupWorkView: function() {
            if(this.$route.name === "work") {

                animateHeaderIn();

                this.setUpSpacers();

                if(window.recentProject.latest === null) {
                    animateWorkItemsIn();
                } else {
                    TweenMax.set(".work-item", {
                        opacity: 1
                    });

                    this.handleBackFromProject();
                }
            }
        },

        setUpSpacers() {
            const spacerWidth = "50vw - (" + this.spacerWidth + "px)";
            const projectsLength = this.projects.projects.length;

            document.querySelector('.work-spacer').setAttribute("style", "width: calc((50vw - " + this.workItemWidth + "px / 2)); " + spacerWidth + "px; height: " + this.workItemHeight + "px; left: calc(" + spacerWidth + " + (" + this.workItemWidth + "px * " + projectsLength + ") + (" + this.workItemWidth / 16 + "px * " + (projectsLength - 1) + "))");
        },

        handleBackFromProject: function() {
            this.setPreviousScrollXPosition();
            this.animateNonViewed();
        },

        animateNonViewed: function() {
            let recent = window.recentProject.latest;
            for(let i = 0; i < this.projects.projects.length; i++) {
                if(i !== recent) {
                    TweenMax.set(".item-" + i, {
                        opacity: 0,
                        scaleX: 0
                    });
                    TweenMax.to(".item-" + i, 0.75, {
                        opacity: 1,
                        scaleX: 1,
                        ease: Power4.easeInOut,
                    });
                }

                TweenMax.set(".item-" + i + " > header", {
                    opacity: 0,
                    scale: 0
                });
                TweenMax.to(".item-" + i + " > header", 0.75, {
                    opacity: 1,
                    scale: 1,
                    ease: Power4.easeInOut,
                });
            }
        },

        setPreviousScrollXPosition: function() {
            if(window.recentProject.latest === null) {
                return;    
            } 

            let elem = document.querySelector(".item-" + window.recentProject.latest);
            let workSpacer = document.querySelector(".work-spacer");
            let offsetXAmount;
            
            if(window.recentProject.latest === this.projects.projects.length - 1) {
                offsetXAmount = 0;
            } else {
                offsetXAmount = workSpacer.offsetWidth;
            }

            TweenMax.set(".work-list", {
                overflow: "hidden",
                scrollTo:{
                    x: elem,
                    offsetX: offsetXAmount,
                }
            });
        
        },
    }
}

</script>

<style lang="scss" scoped>

</style>