import Project from "../Project";
import Image from "../Image";
import AustinBreastfeeding from "../../Views/AustinBreastfeeding";


export default class SwatchitProject extends Project {
    constructor() {
        super();
        this.title            = "Austin Breastfeeding";
        this.route            = {name: "austin-breastfeeding"};
        this.className        = "project-abf";
        this.wrapper          = "project-abf-wrapper";
        this.logo             = new Image("work/abf/work-logo-abf.png").getPath();
        this.heroImage        = new Image("work/abf/work-hero-abf.jpg").getPath();
        this.scrollScreen     = new Image("work/abf/scroll-screen-abf.jpg").getPath();
        this.comps            = new Image("work/abf/work-comps-abf.jpg").getPath();

        this.role             = [
            "Strategy",
            "Visual Design",
            "Statamic Development",
        ],
        this.shortDescription = "Marketing Website";

        this.title1           = "Local Expertise";
        this.description1     = "It was time for a refresh, so we designed and built an entirely new site on the amazing Statamic platform. Not only are we dominating SEO, but we also enjoy fast load times and tighter security. Not to mention full control over the site’s content from the admin.";

        this.title2           = "e-Learning";
        this.description2     = "Next we’ll be launching an e-course with a custom add-on we've developed for Statamic. Users will be able to purchase a video e-course and watch the various sections as they need them. A one-click up-sell will compliment the payment flow, inviting moms to add additional course sections.";
        this.component        = AustinBreastfeeding;
    }
}