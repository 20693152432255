import AnimationSuite       from "./AnimationSuite";
import FadeDownInAnimation  from "../FadeDownInAnimation";
import FadeLeftInAnimation  from "../FadeLeftInAnimation";
import FadeRightInAnimation from "../FadeRightInAnimation";
import RollUpInAnimation    from "../RollUpInAnimation";


export default class HeaderInAnimationSuite extends AnimationSuite {

    constructor() {
        super();
        this.animations = [
            new FadeDownInAnimation(".state-logo", 0.5, 0.5),
            new FadeRightInAnimation(".button-back-wrapper", 0.5, 0.5),
            new FadeLeftInAnimation(".button-hire-wrapper", 0.5, 0.5),
        ];

    }

}