import Project from "../Project";
import Image from "../Image";
import TheWorkOfThePeople from "../../Views/TheWorkOfThePeople";


export default class TheWorkOfThePeopleProject extends Project {
    constructor() {
        super();
        this.title            = "The Work of the People";
        this.route            = {name: "the-work-of-the-people"};
        this.className        = "project-twotp";
        this.wrapper          = "project-twotp-wrapper";
        this.logo             = new Image("work/twotp/work-logo-twotp.png").getPath();
        this.heroImage        = new Image("work/twotp/work-hero-twotp.jpg").getPath();
        this.scrollScreen     = new Image("work/twotp/scroll-screen-twotp.jpg").getPath();
        this.comps            = new Image("work/twotp/work-comps-twotp.jpg").getPath();

        this.role             = [
            "Visual / UX Design",
            "Front-end Development",
            "Back-end Development",
        ],
        this.shortDescription = "Subscription Video Platform";

        this.title1           = "Video Platform";
        this.description1     = "We had the opportunity to redesign The Work of the People’s subscription video platform and add a ton of new features and optimization. This site may seem simple on the surface, but it sports a full dashboard of utilities to manage everything from user memberships and likes to video preview play times.";

        this.title2           = "Custom Curation";
        this.description2     = "One of our favorite additions is a custom page builder for the homepage and marketing pages that provides full control over content while working within our pre-defined layout library.";
        this.component        = TheWorkOfThePeople;
    }
}