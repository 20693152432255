import { lowerCase } from "lodash";

export default class Rule {

    constructor(fieldName, value) {
        this.currentfield = fieldName;
        this.value = value;
    }

    getReadableFieldName() {
        return lowerCase(this.currentfield);
    }

}