import Animation    from "./Animation";
import { TweenMax } from "gsap";

export default class FromToAnimation extends Animation {

    animate() {
        TweenMax.fromTo(this.el, this.timing, this.getFrom(), this.getTo());
    }

    getFrom() {
        //Override in child
    }

    getTo() {
        //Override in child
    }


}