import StaggerUpAnimation  from "../StaggerUpAnimation";
import FadeLeftInAnimation from "../FadeLeftInAnimation";
import FadeInUpAnimation   from "../FadeInUpAnimation";
import AnimationSuite      from "./AnimationSuite";
import GrowUpAnimation     from "../GrowUpAnimation";
import GrowRightAnimation  from "../GrowRightAnimation";

export default class AboutAnimationSuite extends AnimationSuite {

    constructor() {
        super();
        this.animations = [
            new StaggerUpAnimation("p", 0.5, 0.75, 0.25),
            new FadeInUpAnimation(".work-button", 0.5, 1.25),
            new StaggerUpAnimation(".logo-list li", 0.5, 1.25, 0.05),
            new GrowUpAnimation(".logo-list-wrapper .line1", 1.5, 1.5),
            new GrowRightAnimation(".logo-list-wrapper .line2", 1.5, 1.5),
        ]
    }

}