import ToAnimation from "./ToAnimation";

export default class GrowHeroAnimation extends ToAnimation {

    constructor(elem, callback) {
        super(elem, 0.75, 0, callback);
    }


    getTo() {
        return {
            top       : 0,
            zIndex    : 3,
            ease      : Power4.easeOut,
            onComplete: this.callback
        }
    }

}