import FromToAnimation from "./FromToAnimation";

export default class GrowProjectAnimation extends FromToAnimation {

    getFrom() {
        return {
            delay : this.delay,
            width : document.querySelector(this.el).clientWidth,
            height: document.querySelector(this.el).clientHeight,
            ease  : Power4.easeInOut,
            top   : document.querySelector(this.el).offsetTop,
            left  : document.querySelector(this.el).offsetLeft,

            transformOrigin: "center",
        }
    }

    getTo() {
        return {
            delay : this.delay,
            width : "100vw",
            height: "100vh",
            top   : 0,
            left  : 0,
            right : 0,
            ease  : Power4.easeInOut,
        }
    }

}