<template>
    <div>
        <div class="main-background-image"
             :style="{ 'background-image': 'url(' + backgroundImage.getPath() + ')' }"></div>
        <article class="team-content">
            <div>
                <h1 class="heading-big" v-html="title"></h1>
                <team-list></team-list>
            </div>
        </article>
    </div>
</template>

<script>
    import Image           from "../Objects/Image";
    import SplitText       from "../Objects/SplitText";
    import ScrollIndicator from "../Components/ScrollIndicator";
    import TeamList        from "../Components/TeamList";
    import { altNav }      from "../Store.js";
    import PresentationView from "./PresentationView";
    import TeamAnimationSuite from "../Objects/Animations/Suites/TeamAnimationSuite";


    export default {
        extends   : PresentationView,
        components: {
            TeamList,
        },
        data() {
            return {
                previousRoute  : { name: "services" },
                nextRoute      : { name: "work" },
                shared         : altNav,
                wrapper        : "team",
                backgroundImage: new Image("hero-team.jpg")
            }
        },

        computed: {
            title: function() {
                return new SplitText("Your friendly team of Texans ready for action.").get();
            }
        },

        methods: {
            animateElementsIn: function() {

            },

        },

        mounted() {
            //this.$emit("routeChanged", this.backgroundImage.getPath(), this.wrapper);
            this.$emit("routeChanged", "", this.wrapper);
            new TeamAnimationSuite().animate();
            window.recentProject.latest = null;
            this.setBackButtonData(this.previousRoute, "Back");
            this.animateElementsIn();
        },

        beforeDestroy() {
            window.recentProject.latest = null;
        },
    }

</script>

<style scoped>

</style>