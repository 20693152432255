import ToAnimation from "./ToAnimation";

export default class ProjectHeaderOutAnimation extends ToAnimation {

    getTo() {
        return {
            delay       : this.delay,
            opacity     : 0,
            scale       : 0,
            marginBottom: 0,
            ease        : Power4.easeInOut,
        }
    }
}