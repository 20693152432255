import AnimationSuite           from "./AnimationSuite";
import ScrollToProjectAnimation from "../ScrollToProjectAnimation";
import ScrollResetAnimation     from "../ScrollResetAnimation";

export default class WorkListScrollAnimationSuite extends AnimationSuite {


    constructor(offsetX, project) {
        super();

        let scrollTo = new ScrollToProjectAnimation(".work-list", 0.5);
        let reset = new ScrollResetAnimation(".work-list", 0.75, 0.75);

        scrollTo.setOfsetX(offsetX);
        scrollTo.setProject(project);

        this.animations = [
            scrollTo,
            reset
        ];
    }


}