<template>
  <div :class="['circle-loader', { 'is-shown': formSubmitted }, { 'load-complete': formSuccess }]">
    <div class="checkmark draw"></div>
    </div>
</template>

<script>

export default {
    name: "checkmark-indicator",
    props: ["form-submitted", "form-success"],
    methods: {
        
    },
}
</script>
