<template>
    <div>
        <article class="hire-us-content">
            <div>
                <h1 class="heading-big" v-html="title"></h1>
            </div>
            <div class="contact-details">
                <div class="contact-left">
                    <base-form :form="form" @onSubmit="handleFormSubmit" @onSuccess="handleFormSuccess">
                        <label>
                            <div>Name</div>
                            <error :error="form.validator.getError('name')"></error>
                            <input @focus="(e) => focusInput(e)" @blur="(e) => blurInput(e)" @change="form.validateField('name'); executeCaptcha()" v-model="form.name" type="text">
                        </label>
                        <label>
                            <div>Company</div>
                            <error :error="form.validator.getError('company')"></error>
                            <input @focus="(e) => focusInput(e)" @blur="(e) => blurInput(e)" @change="form.validateField('company')" v-model="form.company" type="text">
                        </label>
                        <label>
                            <div>Email</div>
                            <error :error="form.validator.getError('email')"></error>
                            <input @focus="(e) => focusInput(e)" @blur="(e) => blurInput(e)" @change="form.validateField('email')" v-model="form.email" type="email">
                        </label>
                        <label>
                            <div>Phone</div>
                            <error :error="form.validator.getError('phone')"></error>
                            <input @focus="(e) => focusInput(e)" @blur="(e) => blurInput(e)" @change="form.validateField('phone')" v-model="form.phone" type="tel">
                        </label>
                        <label>
                            <div class="textarea-label-text">Message</div>
                            <error :error="form.validator.getError('message')"></error>
                            <textarea @focus="(e) => focusInput(e)" @blur="(e) => blurInput(e)" @change="form.validateField('message')" v-model="form.message"></textarea>
                        </label>

                        <vue-recaptcha ref="captcha"
                                    @verify="verifyCaptcha"
                                    size="invisible"
                                    sitekey="6LdccF4UAAAAACMEICxj5ac13qm7RiwFCQczHugh">
                        </vue-recaptcha>
                        <checkmark-indicator :form-submitted="formSubmitted" :form-success="formSuccess"></checkmark-indicator>
                    </base-form>
                    
                    <transition v-on:enter="animateSuccessMessage">
                        <div v-if="formSuccess" class="form-message">
                            <span class="success" v-html="successMessage"></span>
                        </div>
                    </transition>
                </div>

                <div class="contact-right">
                    <badge-logo></badge-logo>
                    <ul class="social-icons">
                        <li><span><a href="https://instagram.com/statebuilt" target="_blank" title="Instagram"><icon-instagram></icon-instagram></a></span></li>
                        <li><span><a href="https://twitter.com/statebuilt" target="_blank" title="Twitter"><icon-twitter></icon-twitter></a></span></li>
                        <li><span><a href="https://youtube.com/statebuilt" target="_blank" title="YouTube"><icon-youtube></icon-youtube></a></span></li>
                        <li><span><a href="https://facebook.com/statebuilt" target="_blank" title="Facebook"><icon-facebook></icon-facebook></a></span></li>
                    </ul>
                    <ul class="contact-address">
                        <li><span>State Built</span></li>
                        <li><span>205 E. Monroe St.</span></li>
                        <li><span>Austin, Texas</span></li>
                        <li><span><a href="tel:512-587-7590" class="phone">512-587-7590</a></span></li>
                        <li><span><a class="email" href="mailto:info@statebuilt.com">info@statebuilt.com</a></span></li>
                    </ul>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
    import Image                  from "../Objects/Image";
    import { beforeContact, wipe }      from "../Store.js";
    import SplitText              from "../Objects/SplitText";
    import BadgeLogo              from "../Components/BadgeLogo";
    import IconFacebook           from "../Components/IconFacebook";
    import IconInstagram          from "../Components/IconInstagram";
    import IconTwitter            from "../Components/IconTwitter";
    import IconYoutube            from "../Components/IconYoutube";
    import BaseForm               from "../Components/BaseForm";
    import BaseView               from '../Components/BaseView';
    import ContactForm            from "../Objects/ContactForm";
    import Error                  from "../Components/Error";
    import HeaderInAnimationSuite from "../Objects/Animations/Suites/HeaderInAnimationSuite";
    import ContactAnimationSuite  from "../Objects/Animations/Suites/ContactAnimationSuite";
    import { animateHeadingTextIn, animateHeadingTextOut, } from "../Objects/Animations/animation-helpers";
    import VueRecaptcha           from "vue-recaptcha";
    import TweenMax               from "gsap";
    import CheckmarkIndicator     from "../Components/CheckmarkIndicator";
    
    export default {
        extends   : BaseView,
        components: { BadgeLogo, IconFacebook, IconInstagram, IconTwitter, IconYoutube, Error, BaseForm, VueRecaptcha, CheckmarkIndicator },
        data() {
            return {
                wrapper: "contact",
                form   : new ContactForm(),
                formSubmitted: false,
                formSuccess: false,
                successMessage: "Awesome sauce!<br>We look forward to hearing all about your next project!",
            }
        },

        computed: {
            title: function() {
                return new SplitText("Small team, big results. Let's talk about your next project.").get();
            }
        },

        methods: {
            handleFormSubmit() {
                TweenMax.to(".contact-left form button[type='submit']", 0.25, {
                    paddingLeft: "4rem",
                    ease: Power4.ease,
                    onComplete: this.showLoadingIndicator
                });
                TweenMax.set(".circle-loader", {
                    autoAlpha: 0,
                });
            },

            showLoadingIndicator() {
                TweenMax.fromTo(".circle-loader", 0.5, {
                    autoAlpha: 0,
                    ease: Power4.ease,
                }, {
                    autoAlpha: 1,
                })
            },

            handleFormSuccess(response) {
                this.formSuccess = !this.formSuccess;
                this.animateSuccessMessage();
            },

            animateSuccessMessage: function() {
                TweenMax.fromTo(".form-message", 0.25, {
                    autoAlpha: 0,
                    y: 20,
                    ease: Power4.ease,
                }, {
                    autoAlpha: 1,
                    x: 0,
                    ease: Power4.ease,
                });
            },

            focusInput: function(e) {
                let inputWrapper = e.target.closest("label");
                let inputLabel = inputWrapper.firstChild;
                let topPosition;

                if(inputLabel.className === "textarea-label-text") {
                    topPosition = "-1rem";
                } else {
                    topPosition = "-0.75rem";
                }
                if(!e.target.value) {
                    TweenMax.to(inputLabel, 0.25, {
                        top: topPosition,
                        scale: 0.8,
                        left: 0,
                        ease: Power4.ease,
                    });
                }
            },

            blurInput: function(e) {
                let inputWrapper = e.target.closest("label");
                let inputLabel = inputWrapper.firstChild;
                let leftPosition;
                if(inputLabel.className === "textarea-label-text") {
                    leftPosition = "0.75rem";
                } else {
                    leftPosition = 0;
                }
                if(!e.target.value) {
                    TweenMax.to(inputLabel, 0.25, {
                        top: "0.75rem",
                        scale: 1,
                        left: leftPosition,
                        ease: Power4.ease,
                    });
                }
            },

            verifyCaptcha(response) {
                this.form.update("captcha", response);
            },

            executeCaptcha() {
                this.$refs.captcha.execute();
            },

            hasBigHeading() {
                return document.querySelectorAll(".heading-big span").length > 0;
            },

            animateElementsIn: function() {
                new HeaderInAnimationSuite().animate();
                new ContactAnimationSuite().animate();
            },

            animateElementsOut: function() {
                
            },

            setBackButton: function() {
                if(beforeContact === {}) {
                    this.setBackButtonData({name: "home"}, "Home");
                } else {
                    this.setBackButtonData(beforeContact.route, "Back");
                }
            },

            setWipe: function() {
                if(beforeContact === {}) {
                    wipe.left = "home";
                } else {
                    wipe.left = beforeContact.route.name;
                }
            },
        },

        mounted() {
            this.setBackButton();
            this.setWipe();
            this.$emit("routeChanged", "", this.wrapper);
            if(this.hasBigHeading()) {
                animateHeadingTextIn();
            }
            this.animateElementsIn();
        },

        beforeDestroy() {
            window.recentProject.latest = null;
        },
    }

</script>

<style scoped>

</style>