import { TweenMax }    from "gsap";
import FromToAnimation from "./FromToAnimation";

export default class RollUpInAnimation extends FromToAnimation {
    getFrom() {
        return {
            y        : "200%",
            delay    : this.delay,
            ease     : Power4.ease
        }
    }

    getTo() {
        return {
            y        : "0%",
            delay    : this.delay,
            ease     : Power4.ease
        }
    }
}