<script>
import Image                      from '../Objects/Image';
import ScrollRouter               from '../Components/ScrollRouter';
import ProjectDetail              from './ProjectDetail.vue';
import AustinBreastfeedingProject from '../Objects/Projects/AustinBreastfeedingProject';

export default {
	extends: ProjectDetail,
	data() {
		return new AustinBreastfeedingProject()
	},
}

</script>