<template>
	<svg version="1.1" class="icon-phone" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 46.9 71.3" enable-background="new 0 0 46.9 71.3" xml:space="preserve">
        <g>
            <circle fill="#4563AE" cx="23.5" cy="59.6" r="4.3"/>
            <g>
                <path fill="#4563AE" d="M44,2.9v65.4h-41V2.9H44 M46.9,0H0v71.3h46.9V0L46.9,0z"/>
                <g>
                    <rect x="16.5" y="7.9" fill="#4563AE" width="17.9" height="2.7"/>
                    <rect x="11.9" y="7.9" fill="#4563AE" width="2.9" height="2.7"/>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
		name: "icon-phone",
        data() {
            return {
                
            }
        }
    }
</script>
