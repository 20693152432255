import { TweenMax } from "gsap";
import ToAnimation  from "./ToAnimation";

export default class WipeLeftAnimation extends ToAnimation {

    constructor(callback) {
        super(".wipe-up", 0.5, 0);
        this.callback = callback;
    }


    getTo() {
        return {
            opacity   : 1,
            top       : "0",
            onComplete: this.callback,
        };
    }

}