import Vue        from "vue";
import VueRouter  from "vue-router";
import App        from "./Views/App";
import { router } from "./Router";
import Vue2Touch  from 'vue2-touch';

Vue.use(VueRouter);

Vue.use(Vue2Touch, {
    gestures: ['pan'],
    directions: {
        pan: ['panend']
    }
});

window.Broadcast = new Vue();

window.recentProject = {
    latest: null,
};

const app = new Vue({
    el        : "#app",
    components: { App },
    router
});