<template>
	<svg version="1.1" class="icon-facebook" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 9.2 17.2" style="enable-background:new 0 0 9.2 17.2;" xml:space="preserve">
        <path class="st0" d="M2,3.7c0,0.5,0,2.6,0,2.6H0v3.2h2v7.6h4.1V9.6h2.7c0,0,0.3-1.5,0.4-3.2c-0.4,0-3.1,0-3.1,0s0-1.9,0-2.2
            c0-0.3,0.4-0.8,0.9-0.8s1.4,0,2.2,0c0-0.4,0-2,0-3.4C8,0,6.7,0,6.2,0C1.9,0,2,3.2,2,3.7z"/>
    </svg>
</template>

<script>
    export default {
		name: "icon-facebook",
        data() {
            return {
                
            }
        }

    }
</script>
