<template>
	<svg version="1.1" class="icon-ux" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 29.6 21.5" style="enable-background:new 0 0 29.6 21.5;" xml:space="preserve">
        <g>
            <polygon class="st0" points="0.9,16.4 0.9,0.9 26.2,0.9 26.2,11.3 27.1,11.7 27.1,0 0,0 0,17.3 19.2,17.3 18.8,16.4 	"/>
            <g>
                <path class="st0" d="M16.7,11.9c-0.3,0.1-0.5,0.1-0.8,0.1c-2,0-3.7-1.7-3.7-3.7s1.7-3.7,3.7-3.7s3.7,1.7,3.7,3.7
                    c0,0.2,0,0.3,0,0.5l0.8,0.3c0-0.3,0.1-0.5,0.1-0.8c0-2.5-2-4.5-4.5-4.5s-4.5,2-4.5,4.5s2,4.5,4.5,4.5c0.4,0,0.8-0.1,1.2-0.2
                    L16.7,11.9z"/>
                <path class="st0" d="M15.9,6c-1.2,0-2.2,1-2.2,2.2c0,1.2,1,2.2,2.2,2.2c0,0,0.1,0,0.1,0l-0.4-0.8c-0.6-0.1-1.1-0.7-1.1-1.4
                    c0-0.8,0.6-1.4,1.4-1.4c0.6,0,1.1,0.4,1.3,1l0.9,0.3C18.1,7,17.1,6,15.9,6z"/>
            </g>
            <path class="st0" d="M26.1,15.1l1.2-0.5l0.9-0.4l1.4-0.6L28.2,13l-1-0.4L15.9,8.2l4,8.6l0.5,0.9l1.8,3.8l1.3-3.5l1.9,1.9l2.8-2.9
                L26.1,15.1z M25.4,18.6l-2.3-2.3l-1,2.8l-4.3-9.2l9.5,3.7l-2.7,1.2l2.3,2.3L25.4,18.6z"/>
        </g>
    </svg>
</template>

<script>
    export default {
		name: "icon-ux",
        data() {
            return {
                
            }
        }
    }
</script>
