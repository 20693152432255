import { TweenMax }     from "gsap";
import StaggerAnimation from "./StaggerAnimation";

export default class StaggerUpOutAnimation extends StaggerAnimation {

    getFrom() {
        return{
            delay: this.delay,
            opacity: 1,
            y: 0,
            scale: 1,
            ease: Power0.ease,
        }
    }

    getTo() {
        return {
            delay  : this.delay,
            opacity: 0,
            y      : -60,
            scale  : 1,
            ease   : Power0.ease,
        }
    }

}