import Image from '../Objects/Image';

export default class Project {

    constructor() {
        this.isActive = false;
    }

    activate() {
        this.isActive = true;
    }

    deactivate() {
        this.isActive = false;
    }
}