import { TweenMax } from "gsap";
import ToAnimation  from "./ToAnimation";

export default class ScaleUpAnimation extends ToAnimation {

    getTo() {
        return {
            autoAlpha: 0,
            scale    : 1.25,
            delay    : this.delay,
            ease     : Power1.ease,
        }
    }

}