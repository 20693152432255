import Project from "../Project";
import Image from "../Image";
import Swatchit from "../../Views/Swatchit";


export default class SwatchitProject extends Project {
    constructor() {
        super();
        this.title            = "Swatchit";
        this.route            = {name: "swatchit"};
        this.className        = "project-swatchit";
        this.wrapper          = "project-swatchit-wrapper";
        this.logo             = new Image("work/swatchit/work-logo-swatchit.png").getPath();
        this.heroImage        = new Image("work/swatchit/work-hero-swatchit.jpg").getPath();
        this.scrollScreen     = new Image("work/swatchit/scroll-screen-swatchit.jpg").getPath();
        this.comps            = new Image("work/swatchit/work-comps-swatchit.jpg").getPath();

        this.role             = [
            "Concept",
            "Visual / UX Design",
            "Web Application Development",
        ],
        this.shortDescription = "Web Application";

        this.title1           = "Swatchit.io";
        this.description1     = "We needed a place to store and share color values with our clients and team, but couldn't find an app that could handle large palettes. So we created Swatchit.io, a streamlined color tool built on the Vue.js framework. Swatchit is free, and since it's a web app there's nothing to install, so please give it a try! ";

        this.title2           = "Smooth Operator";
        this.description2     = "Using a framework like Vue.js (similar to React) gives us the ability to live-update text and color values on the fly, without a page refresh. Which translates to a very native-like feel and increased workflow speed. We'll be continuing to add features to Swatchit throughout the year. Stay tuned.";
        this.component        = Swatchit;
    }
}