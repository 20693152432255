import Project from "../Project";
import Image from "../Image";
import FranklinBuildingSupply from "../../Views/FranklinBuildingSupply";


export default class FranklinBuildingSupplyProject extends Project {
    constructor() {
        super();
        this.title            = "Franklin Building Supply";
        this.route            = {name: "franklin-building-supply"};
        this.className        = "project-fbs";
        this.wrapper          = "project-fbs-wrapper";
        this.logo             = new Image("work/fbs/work-logo-fbs.png").getPath();
        this.heroImage        = new Image("work/fbs/work-hero-fbs.jpg").getPath();
        this.scrollScreen     = new Image("work/fbs/scroll-screen-fbs.jpg").getPath();
        this.comps            = new Image("work/fbs/work-comps-fbs.jpg").getPath();

        this.role             = [
            "Visual Design",
            "Statamic Development",
            "Icon Design",
        ],
        this.shortDescription = "Marketing Website";

        this.title1           = "Service Focused";
        this.description1     = "Franklin differentiates itself from the other big-box hardware stores with customer service and expertise, catering to building professionals. They wanted the ability to showcase their strengths and commitment to community, while also providing the essential location and product information you'd expect. We built on the Statamic CMS system to create a uniquely manageable platform with excellent performance and stability.";

        this.title2           = "Design System";
        this.description2     = "Making a design system is hard, and that’s exactly what we did for Franklin Building Supply. All key pages are created in the admin panel from a selection of custom-designed layout elements. This gives marketing creation power without sacrificing consistency, functionality, or polish. We also created over 50 custom icons.";
        this.component        = FranklinBuildingSupply;
    }
}