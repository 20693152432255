import AnimationSuite          from "./AnimationSuite";
import ScrollWindowUpAnimation from "../ScrollWindowUpAnimation";
import GrowHeroAnimation       from "../GrowHeroAnimation";

export default class ScrollToTopAnimationSuite extends AnimationSuite {

    constructor(callback) {
        super();
        this.animations = [
            //new ScrollWindowUpAnimation(),
            new GrowHeroAnimation('.next-hero', callback),
        ];
    }

}