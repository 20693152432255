<script>
import ProjectDetail from './ProjectDetail.vue';
import StartupRevivalProject from '../Objects/Projects/StartupRevivalProject';

export default {
    extends: ProjectDetail,
    data() {
        return new StartupRevivalProject()
    },
}

</script>

<style scoped>

</style>