import AnimationSuite            from "./AnimationSuite";
import GrowProjectAnimation      from "../GrowProjectAnimation";
import ProjectHeaderOutAnimation from "../ProjectHeaderOutAnimation";

export default class GrowProjectAnimationSuite extends AnimationSuite {

    constructor(elem) {
        super();
        this.animations = [
            new GrowProjectAnimation(elem, 0.75, 0.75),
            new ProjectHeaderOutAnimation(elem + " > header", 0.75, 0.5),
        ];
    }


}