import ToAnimation from "./ToAnimation";

export default class ScrollResetAnimation extends ToAnimation {

    getTo() {
        return {
            delay   : this.delay,
            ease    : Power4.easeInOut,
            overflow: "hidden",
            scrollTo: {
                x      : 0,
                offsetX: 0,
            }
        }

    }
}