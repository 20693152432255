<script>
    import _throttle                 from "lodash.throttle";
    import { TweenMax }              from "gsap";
    import ScrollToPlugin            from "gsap/ScrollToPlugin"
    import BaseView                  from "../Components/BaseView";
    import WipeRightAnimation        from "../Objects/Animations/WipeRightAnimation";
    import FadeDownOutAnimation      from "../Objects/Animations/FadeDownOutAnimation";
    import ScrollToTopAnimationSuite from "../Objects/Animations/Suites/ScrollToTopAnimationSuite";
    import { getScrollWheelForce }   from "../helpers";
    import FadeInUpAnimation         from "../Objects/Animations/FadeInUpAnimation";

    export default {
        extends: BaseView,

        data() {
            return {
                triggerElemId      : "scrollRouterTrigger",
                nextHero           : null,
                startingTouch      : {},
                allowedToChangePage: false,
            }
        },


        computed: {
            triggerPosition: function() {
                let scrollTrigger = document.getElementById(this.triggerElemId);
                let triggerPosition = scrollTrigger.offsetTop + scrollTrigger.offsetHeight;

                return triggerPosition;
            }
        },

        methods: {
            handleScroll: _throttle(function(e) {
                if(this.isInTriggerPosition() && this.allowedToChangePage) {
                    let wheelForce = getScrollWheelForce(e);
                    if(wheelForce <= -0.025) {
                        this.changePage();
                    }
                }
                return false;
            }, 1500, { trailing: false }),

            handleTouchStart(touchData) {
                this.startingTouch = touchData;
                window.Broadcast.$emit("scroll-router-touch");
            },

            handleTouchEnd(touchData) {
                let touchForce = this.getTouchForce(touchData);

                if(this.isInTriggerPosition() && this.allowedToChangePage && touchForce > 0.7) {
                    this.changePage();
                }
            },

            getTouchForce(touch) {
                let timeBetween = touch.timeStamp - this.startingTouch.timeStamp;

                return (this.getTouchY(this.startingTouch) - this.getTouchY(touch)) / timeBetween;
            },

            getTouchY(touch) {
                return touch.changedTouches[0].clientY;
            },

            isInTriggerPosition() {
                return this.getWindowBottomPosition() >= this.triggerPosition - 1;
            },

            changePage() {
                this.pageTransition(this.changeRoute);
                this.disableScrolling();

                if(typeof this.animateElementsOut === "function") {
                    this.animateElementsOut();
                }

                this.enableScrolling();
            },


            pageTransition(afterTransitionCallback) {
                new ScrollToTopAnimationSuite(afterTransitionCallback).animate();
                new FadeDownOutAnimation(".scroll-indicator", 0.5).animate();
                if(this.nextHero === null) {
                    new WipeRightAnimation(afterTransitionCallback).animate();
                }
            },

            changeRoute() {
                this.$router.push(this.nextRoute);
            },

            disableScrolling() {
                document.body.classList.add("scrolling-disabled");
            },

            enableScrolling: function() {
                setTimeout(function() {
                    document.body.classList.remove("scrolling-disabled");
                }, 1100);
            },

            getScrollPosition() {
                return window.pageYOffset;
            },

            getWindowBottomPosition() {
                return this.getScrollPosition() + window.innerHeight;
            },

            delayBeforeCanChangePageWithScroll() {
                TweenMax.set(".scroll-indicator", { autoAlpha: 0 });
                setTimeout(() => {
                    this.allowedToChangePage = true;
                    new FadeInUpAnimation(".scroll-indicator", 0.75).animate();
                }, 1500);
            }

        },

        mounted() {
            this.delayBeforeCanChangePageWithScroll();
            document.addEventListener("mousewheel", this.handleScroll);
            document.addEventListener("DOMMouseScroll", this.handleScroll);
            document.addEventListener("touchstart", this.handleTouchStart);
            document.addEventListener("touchend", this.handleTouchEnd);
        },

        created() {
            window.Broadcast.$on("scroll-indicator-click", this.changePage);
        },

        beforeDestroy() {
            document.removeEventListener("mousewheel", this.handleScroll);
            document.removeEventListener("DOMMouseScroll", this.handleScroll);
            document.removeEventListener("touchstart", this.handleTouchStart);
            document.removeEventListener("touchend", this.handleTouchEnd);
        }
    }
</script>
