import Animation    from "./Animation";
import { TweenMax } from "gsap";

export default class ToAnimation extends Animation {


    animate() {
        TweenMax.to(this.el, this.timing, this.getTo());
    }

}