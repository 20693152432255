<script>
import Image                         from '../Objects/Image';
import ScrollRouter                  from '../Components/ScrollRouter';
import ProjectDetail                 from './ProjectDetail.vue';
import FranklinBuildingSupplyProject from '../Objects/Projects/FranklinBuildingSupplyProject';

export default {
	extends: ProjectDetail,
	data() {
		return new FranklinBuildingSupplyProject()
	},
}

</script>