<template>
	<svg version="1.1" class="icon-twitter" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 21.4 17.7" style="enable-background:new 0 0 21.4 17.7;" xml:space="preserve">
        <path class="st0" d="M10.5,5.5c0,0-4.8,0.4-9.3-4.7c0,0-1.8,3.3,1.3,5.9c0,0-0.4,0.5-1.9-0.4c0,0-0.1,3.3,3.4,4.2
            c0,0-0.2,0.5-1.8,0.3c0,0,0.8,2.7,4.1,3c0,0-2.8,2.4-6.4,1.9c0,0,2.7,1.9,6.6,1.9c4.8,0.1,12-3.4,12.5-11.9c0,0,0.1-1.1,0-1.4
            c0,0,1.6-1.1,2.2-2.2c0,0-1.9,0.6-2.3,0.5c0,0,1.4-1,1.8-2.3c0,0-2.1,0.9-2.7,0.9c0,0-1.2-1.4-3.5-1.3c-2.4,0.1-4.2,2.3-4.2,4.4
            C10.4,5,10.5,5.5,10.5,5.5z"/>
    </svg>
</template>

<script>
    export default {
		name: "icon-twitter",
        data() {
            return {
                
            }
        }
    }
</script>
