<template>
    <div>
        <div class="main-background-image" :style="{ 'background-image': 'url(' + backgroundImage.getPath() + ')' }"></div>
        <article class="services-content">
            <div>
                <h1 class="heading-big" v-html="title"></h1>

                <div class="services-detail">
                    <div class="services-text">
                        <p>
                            We enjoy hearing your story and learning the goals that will translate to a successful project for your organization. Communication is a big part of what we do. Being located in Austin means we're around and ready to chat during your normal business hours.  
                        </p>
                        <p>
                            Our passion for cutting-edge web technology and design trends shapes the way we approach projects and ultimately yields an outcome that will resonate with your audience.
                        </p>
                        <div class="work-button-wrapper">
                            <work-button button-text="Our Work" route-name="work"></work-button>
                        </div>
                    </div>

                    <div class="services-list">
                        <ul class="icon-list">
                            <li>
                                <div>
                                    <icon-bulb></icon-bulb>
                                    <span>Strategy</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <icon-branding></icon-branding>
                                    <span>Branding</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <icon-design></icon-design>
                                    <span>Design</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <icon-marketing></icon-marketing>
                                    <span>Marketing</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <icon-ux></icon-ux>
                                    <span>UX / UI</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <icon-phone></icon-phone>
                                    <span>Mobile</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <icon-dev></icon-dev>
                                    <span>Development</span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <icon-data></icon-data>
                                    <span>Automation</span>
                                </div>
                            </li>
                        </ul>
                        <div class="small-services-list">
                            <ul>
                                <li v-for="(service, index) in serviceList" :key="index">
                                    <span>{{ service }}</span>
                                </li>
                            </ul>
                            <span class="line1"></span>
                            <span class="line2"></span>
                            <span class="line-solid"></span>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
    import Image                  from "../Objects/Image";
    import SplitText              from "../Objects/SplitText";
    import WorkButton             from "../Components/WorkButton";
    import IconBulb               from "../Components/IconBulb";
    import IconData               from "../Components/IconData";
    import IconDesign             from "../Components/IconDesign";
    import IconMarketing          from "../Components/IconMarketing";
    import IconUx                 from "../Components/IconUx";
    import IconPhone              from "../Components/IconPhone";
    import IconDev                from "../Components/IconDev";
    import IconBranding           from "../Components/IconBranding";
    import { altNav }             from "../Store.js";
    import HeaderInAnimationSuite from "../Objects/Animations/Suites/HeaderInAnimationSuite";
    import PresentationView       from "./PresentationView";
    import ServicesAnimationSuite from "../Objects/Animations/Suites/ServicesAnimationSuite";


    export default {
        extends   : PresentationView,
        components: {
            WorkButton,
            IconBulb,
            IconUx,
            IconData,
            IconDesign,
            IconPhone,
            IconDev,
            IconMarketing,
            IconBranding,
            Blob,
        },
        data() {
            return {
                previousRoute  : { name: "about" },
                nextRoute      : { name: "team" },
                wrapper        : "services",
                backgroundImage: new Image("hero-services.jpg"),
                shared         : altNav,
                serviceList    : [
                    "Web Development",
                    "WordPress",
                    "Statamic",
                    "Laravel",
                    "VueJS",
                    "Web Applications",
                    "Marketing Automation",
                    "Strategy",
                    "Branding / Logos",
                    "Web Design",
                    "Mobile Design",
                    "Print Design",
                ],
            }
        },

        computed: {
            title: function() {
                return new SplitText("Just development, or start to finish.").get();
            }
        },

        methods: {

            setListeners: function() {
                let vm = this;
                document.getElementById("back-button").addEventListener('click', vm.goBack);
            },

            removeListeners  : function() {
                let vm = this;
                document.getElementById("back-button").addEventListener('click', vm.goBack);
            },
            animateElementsIn: function() {
                new HeaderInAnimationSuite().animate();
                new ServicesAnimationSuite().animate();
            },

        },

        beforeDestroy() {
            window.recentProject.latest = null;
            this.removeListeners();
        },

        mounted() {
            this.$emit("routeChanged", this.backgroundImage.getPath(), this.wrapper);
            window.recentProject.latest = null;
            this.setBackButtonData(this.previousRoute, "Back");
            this.setListeners();
            this.animateElementsIn();
        },

    }

</script>

<style scoped>

</style>