<template>
	<div class="state-logo">
		<a href="/" class="logo-wrapper" @click.prevent="onClick">
			<svg version="1.1" class="logo-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 143.9 36.6" style="enable-background:new 0 0 143.9 36.6; max-width: 150px; max-height: 38px;" xml:space="preserve">
				<g>
					<g>
						<path class="st0" d="M68.9,8.7c1.3,0,3-0.9,3.7-2L75.6,2c0.7-1.1,0.2-2-1.1-2H50.7c-1.3,0-3,0.9-3.7,2l-2.9,4.6
							c-0.7,1.1-0.2,2,1.1,2h1.1c1.3,0,1.8,0.9,1.1,2l-9.2,14.5c-0.7,1.1-1.9,1.1-2.6,0l-9.9-14.6c-0.7-1.1-0.3-2,1.1-2h6.4
							c1.3,0,3-0.9,3.7-2L39.8,2c0.7-1.1,0.2-2-1.1-2H22.1c-1.3,0-3,0.9-3.7,2l-3,4.6c-0.7,1.1-0.7,2.9,0.1,4l10.4,15.3
							c0.7,1.1,0.3,2-1.1,2L7,27.9c-1.3,0-3,0.9-3.7,2l-3,4.6c-0.7,1.1-0.2,2,1.1,2h28.2c1.3,0,2.4,0,2.4,0c0,0,1.1,0,2.4,0l4.6,0
							c1.3,0,3-0.9,3.7-2l15.1-23.8c0.7-1.1,2.4-2,3.7-2H68.9z"/>
					</g>
					<g>
						<path class="st0" d="M136.9,8.6c1.3,0,3-0.9,3.7-2l2.9-4.6c0.7-1.1,0.2-2-1.1-2L87.7,0c-1.3,0-3,0.9-3.7,2l-3,4.6
							c-0.7,1.1-0.2,2,1.1,2h9c1.3,0,1.8,0.9,1.1,2l-6.8,11c-0.7,1.1-1.8,1.1-2.5,0L78.2,14c-0.7-1.1-2.3-2.1-3.7-2.1h-8.1
							c-1.3,0-3,0.9-3.7,2.1L50.3,34.6c-0.7,1.1-0.2,2.1,1.2,2.1l5.5,0c1.3,0,3-0.9,3.7-2.1l8.7-14.3c0.7-1.1,1.8-1.1,2.5,0l4.2,6.8
							c0.7,1.1,0.2,2.1-1.2,2.1l-3.5,0c-1.3,0-1.8,0.9-1,1.9l2.7,3.5c0.8,1.1,2.5,1.9,3.9,1.9l12.7,0c1.3,0,1.8-0.9,1.2-2.1L90.3,34
							c-0.7-1.1-0.7-3,0-4.1l12.1-19.1c0.7-1.1,2.4-2,3.7-2h2.6c1.3,0,1.8,0.9,1.1,2l-15,23.9c-0.7,1.1-0.2,2,1.1,2h24.7
							c1.3,0,3-0.9,3.7-2l3-4.7c0.7-1.1,0.2-2-1.1-2l-14.5,0c-1.3,0-1.8-0.9-1.1-2l0.5-0.8c0.7-1.1,2.4-2,3.7-2l1,0c1.3,0,3-0.9,3.7-2
							l2.9-4.6c0.7-1.1,0.2-2-1.1-2l-1.1,0c-1.3,0-1.8-0.9-1.1-2l1.1-1.7c0.7-1.1,2.4-2,3.7-2L136.9,8.6z"/>
					</g>
				</g>
			</svg>
		</a>
	</div>
</template>

<script>
	import { wipe }                                                      from "../Store";
	import { animateBackgroundOut, 
			 animateContentOut, 
			 animateHeaderOut,
			 animateProjectDetailFooterOut } from "../Objects/Animations/animation-helpers";
	import WipeLeftAnimation from "../Objects/Animations/WipeLeftAnimation";

    export default {
		name: "state-logo",
        data() {
            return {
                
            }
		},
		
		methods: {
			onClick: function() {
				let vm = this;
				if(this.$route.name !== "home") {
					wipe.left = "home";

					animateHeaderOut();
					animateProjectDetailFooterOut();
					animateContentOut(vm.$route.name);
					
					if(this.$route.fullPath.indexOf("work") === -1) {
						animateBackgroundOut();
					}
					new WipeLeftAnimation(this.goHome).animate();
				}
			},

			goHome: function() {
				this.$router.push({name: "home"});
			}
		}

    }

</script>

<style lang="scss" scoped>

</style>
