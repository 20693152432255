import RollUpInAnimation   from "../RollUpInAnimation";
import FadeLeftInAnimation from "../FadeLeftInAnimation";
import FadeInUpAnimation   from "../FadeInUpAnimation";
import StaggerUpAnimation  from "../StaggerUpAnimation";
import AnimationSuite      from "./AnimationSuite";

export default class ContactAnimationSuite extends AnimationSuite {

    constructor() {
        super();
        this.animations = [
            new StaggerUpAnimation(".contact-left label", 0.5, 0.5, 0.15),
            new FadeInUpAnimation('.contact-left button[type="submit"]', 0.5, 1.55),
            new FadeInUpAnimation(".badge-logo", 0.5, 1.75),
            new RollUpInAnimation(".social-icons li a", 0.5, 1.65),
            new RollUpInAnimation(".contact-address li span", 0.5, 1.65),
        ]
    }

}