<template>
	<svg version="1.1" class="icon-design" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 16.5 30" style="enable-background:new 0 0 16.5 30;" xml:space="preserve">
        <path class="st0" d="M12.4,18.1h2.9v-1.3h-3.5L10.2,13V8.8c1.2-0.7,2.1-2,2.1-3.5c0-2-1.5-3.7-3.3-4V0H7.6v1.3
        c-1.9,0.3-3.3,1.9-3.3,4c0,1.5,0.8,2.8,2.1,3.5v4.3l-1.7,3.7H1.1v1.2h2.9L0,27.2L0.2,30l5.3-11.9h2.1v1.2h1.3v-1.2H11L16.3,30
        l0.2-2.7L12.4,18.1z M5.5,5.3c0-1.5,1.2-2.7,2.7-2.7S11,3.8,11,5.3C11,6.8,9.8,8,8.3,8C6.7,8,5.5,6.8,5.5,5.3z M8.9,16.8v-1H7.6v1
        H6.1l1.5-3.4h1.3l1.5,3.4H8.9z"/>
    </svg>

</template>

<script>
    export default {
		name: "icon-design",
        data() {
            return {
                
            }
        }

    }
</script>
