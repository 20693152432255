import { TweenMax } from "gsap";
import ToAnimation  from "./ToAnimation";

export default class WipeRightAnimation extends ToAnimation {

    constructor(callback) {
        super(".wipe-right", 0.5, 0);
        this.callback = callback
    }


    getTo() {
        return {
            opacity   : 1,
            left      : "0vw",
            skewX     : "0deg",
            onComplete: this.callback,
        };
    }

}