<template>
  <button :class="['scroll-indicator', 'animating', {'isTouch':isTouch}, {'isMouse':!isTouch} ]" title="Scroll..." @click="onClick">
        <div class="scroll-visual">
            <span>{{ text }}</span>
            <div class="line">
                <div class="dot"></div>
            </div>
        </div>
    </button>
</template>

<script>
import WipeRightAnimation from "../Objects/Animations/WipeRightAnimation";

export default {
    name: "scroll-indicator",

    data() {
        return {
            text: "Scroll",
            isTouch: false,
        }
    },

    mounted() {
        this.animate();
    },

    updated() {
        this.animate();
    },

    created() {
        window.Broadcast.$on('scroll-router-touch', this.setTouch);
        if(window.innerWidth < 769) {
            this.setTouch();
        }
    },

    methods: {
        animate: function() {
            let dotStart;
            let dotEnd;
            
            if(!this.isTouch) {
                dotStart = -8;
                dotEnd = 66;
            } else {
                dotEnd = -8;
                dotStart = 54;
            }

            TweenMax.fromTo(".scroll-indicator .dot", 1, {
                autoAlpha: 1,
                top: dotStart,
                repeat: -1,
                repeatDelay: 2,
                ease: Power0.ease,
            }, {
                autoAlpha: 1,
                top: dotEnd,
                repeat: -1,
                repeatDelay: 2,
                ease: Power0.ease,
            });
        },

        onClick: function() {
            window.Broadcast.$emit('scroll-indicator-click')
        },

        setTouch() {
            this.text = "Swipe";
            this.isTouch = true;
        }
    },
}
</script>
