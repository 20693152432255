<template>
    <form @submit.prevent="handleSubmit">
        <h3><slot name="form-title"></slot></h3>
        <slot></slot>
        <button type="submit" :disabled="disableForm"><slot name="button-text">Send</slot></button>
    </form>
</template>

<script>
    export default {
        name: "BaseForm",
        props: {
           form: {}
        },

        data() {
            return {
                disableForm: false,
            }
        },

        methods: {
            handleSubmit() {
                this.disableForm = true;

                this.form.validate();
                if(this.form.validator.fails()) {
                    return this.disableForm = false;
                }

                this.$emit("onSubmit");
                
                this.form.submit().then(response => {
                    this.$emit("onSuccess", response);
                });
            },
        }
    }
</script>

<style scoped>

</style>