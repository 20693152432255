import { TweenMax }    from "gsap";
import FromToAnimation from "./FromToAnimation";

export default class GrowUpAnimation extends FromToAnimation {
    getFrom() {
        return {
            scaleY   : 0,
            transformOrigin: "bottom center",
            delay    : this.delay,
            ease     : Power4.easeOut
        }
    }

    getTo() {
        return {
            scaleY   : 1,
            delay    : this.delay,
            ease     : Power4.easeOut
        }
    }
}