import VueRouter from "vue-router";

import Home from "./Views/Home";
import Work from "./Views/Work";

import About    from "./Views/About";
import Services from "./Views/Services";
import Team     from "./Views/Team";

import CityLifeChurch               from "./Views/CityLifeChurch";
import Serenity                     from "./Views/Serenity";
import StartupRevival               from "./Views/StartupRevival";
import GodTv                        from "./Views/GodTv";
import Swatchit                     from "./Views/Swatchit";
import TheWorkOfThePeople           from "./Views/TheWorkOfThePeople";
import AustinBreastfeeding          from "./Views/AustinBreastfeeding";
import FranklinBuildingSupply       from "./Views/FranklinBuildingSupply";

import Contact from "./Views/Contact";

import { beforeContact, beforeWork } from "./Store";


let router = new VueRouter({
    mode  : "history",
    routes: [
        {
            path     : "/",
            name     : "home",
            component: Home
        },
        {
            path     : "/work",
            name     : "work",
            component: Work
        },
        {
            path     : "/work/city-life-church",
            name     : "city-life-church",
            component: CityLifeChurch
        },
        {
            path     : "/work/serenity-research",
            name     : "serenity",
            component: Serenity
        },
        {
            path     : "/work/startup-revival",
            name     : "startup-revival",
            component: StartupRevival
        },
        {
            path     : "/work/godtv",
            name     : "godtv",
            component: GodTv
        },
        {
            path     : "/work/swatchit",
            name     : "swatchit",
            component: Swatchit
        },
        {
            path     : "/work/the-work-of-the-people",
            name     : "the-work-of-the-people",
            component: TheWorkOfThePeople
        },
        {
            path     : "/work/austin-breastfeeding",
            name     : "austin-breastfeeding",
            component: AustinBreastfeeding
        },
        {
            path     : "/work/franklin-building-supply",
            name     : "franklin-building-supply",
            component: FranklinBuildingSupply
        },
        {
            path     : "/about",
            name     : "about",
            component: About
        },
        {
            path     : "/team",
            name     : "team",
            component: Team
        },
        {
            path     : "/services",
            name     : "services",
            component: Services
        },
        {
            path     : "/contact",
            name     : "contact",
            component: Contact
        },
        { // 404 Redirect to Home
            path     : "*",
            redirect: { name: "home" }
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({ x: 0, y: 0 })
            }, 500)
        })
    },
});
router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    setBeforeWork(to, from);
    setBeforeContact(to, from);
    next();
});


function setBeforeWork(to, from) {
    if( ! comingFromAProject(from) && ! comingFromContact(from)) {
        beforeWork.route = { name: from.name };
    }
}

function setBeforeContact(to, from) {
    if(to.name === "contact") {
        beforeContact.route = { name: from.name };
    }
}

function comingFromAProject(from) {
    return from.fullPath.indexOf("work") !== -1
}

function comingFromContact(from) {
    return from.name === "contact"
}

export { router };






