export default class AnimationSuite {

    constructor() {
        this.animations = []
    }

    animate() {
        for(let i in this.animations) {
            this.animations[i].animate();
        }
    }

}