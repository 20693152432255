<template>
    <footer class="main-footer">
        <span>State Built {{ year }}</span><span>Austin <icon-texas></icon-texas> Texas</span><span>Web Builders</span>
    </footer>
</template>

<script>
    import IconTexas           from "./IconTexas";
    import { TweenMax }        from "gsap";
    import RollUpInAnimation   from "../Objects/Animations/RollUpInAnimation";
    import RollDownInAnimation from "../Objects/Animations/RollDownInAnimation";

    export default {
        name      : "main-footer",
        components: {
            IconTexas,
        },
        computed: {
            year() {
                return new Date().getFullYear();
            }
        },
        data() {
            return {}
        },

        methods: {

            animateIn() {
                if(this.onWorkPage()) {
                    new RollUpInAnimation('.main-footer > span', 0.5, 0.5).animate();
                }
                else {
                    new RollDownInAnimation('.main-footer > span', 0.5, 0.5).animate();
                }
            },

            setRotation() {
                if(this.onWorkPage()) {
                    this.setFooterHorizontal();
                }
                else {
                    this.setFooterVertical();
                }
            },

            setFooterHorizontal() {
                TweenMax.set('.main-footer', { rotation: 0 });
            },

            setFooterVertical() {
                TweenMax.set('.main-footer', { rotation: 90 });
            },

            onWorkPage() {
                return this.$route.name === 'work';
            }

        },

        mounted() {
            this.setRotation();
            this.animateIn();
        },

        watch: {
            '$route.name': function() {
                this.setRotation();
                this.animateIn();
            }
        }

    }
</script>



