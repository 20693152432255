<template>
	<svg version="1.1" class="icon-data" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 72 72" enable-background="new 0 0 72 72" xml:space="preserve">
        <g>
            <g>
                <rect x="21.4" y="26.7" transform="matrix(0.6713 0.7412 -0.7412 0.6713 42.112 -1.65)" width="3.1" height="39.9"/>
            </g>
            <path d="M35,15.8c-6.8,0-12.8,3.6-16.2,8.9l2.7,1.5c2.9-4.4,7.8-7.3,13.5-7.3c8.9,0,16.1,7.2,16.1,16.1c0,8.9-7.2,16.1-16.1,16.1
                c-2.9,0-5.6-0.8-7.9-2.1l-2.4,2.2c3,1.9,6.5,3,10.3,3c10.6,0,19.2-8.6,19.2-19.2S45.5,15.8,35,15.8z"/>
            <path d="M35,7C31.2,7,27.6,7.7,24.4,9l1.2,2.8c2.9-1.2,6-1.8,9.3-1.8C48.7,10,59.9,21.2,59.9,35S48.7,59.9,35,59.9
                c-5.5,0-10.6-1.8-14.7-4.8l-2.3,2.1c4.7,3.6,10.6,5.8,17,5.8c15.4,0,28-12.6,28-28C63,19.5,50.4,7,35,7z"/>
            <path d="M48.4,0.7c-0.3,0.9-0.7,1.8-1.1,2.9C59.8,8.5,68.7,20.7,68.7,35c0,18.6-15.1,33.7-33.7,33.7c-8.1,0-15.5-2.9-21.3-7.6
                l-2.3,2.1c6.4,5.4,14.7,8.6,23.7,8.6c20.3,0,36.8-16.5,36.8-36.8C71.8,19.4,62.1,6.1,48.4,0.7z"/>
        </g>
    </svg>
</template>

<script>
    export default {
		name: "icon-data",
        data() {
            return {
                
            }
        }

    }
</script>
