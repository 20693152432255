import { TweenMax }    from "gsap";
import FromToAnimation from "./FromToAnimation";

export default class ScaleDownAnimation extends FromToAnimation {
    
    getFrom() {
        return {
            autoAlpha: 0,
            scale    : 1.3,
            delay    : this.delay
        }
    }

    getTo() {
        return {
            autoAlpha      : 1,
            scale          : 1,
            delay          : this.delay,
            transformOrigin: '50% 50%',
            ease           : Power4.ease,
        }
    }

}