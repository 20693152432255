<template>
	<svg version="1.1" class="icon-dev" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 34.8 20.1" style="enable-background:new 0 0 34.8 20.1;" xml:space="preserve">
        <g>
            <polygon class="st0" points="10.2,3.6 0,10.7 10.2,17.5 10.9,16.5 2.2,10.6 10.9,4.6 	"/>
            <polygon class="st0" points="24.6,3.6 23.9,4.6 32.6,10.5 23.9,16.5 24.6,17.5 34.8,10.5 	"/>
            <rect x="7.2" y="9.4" transform="matrix(0.4069 -0.9135 0.9135 0.4069 1.4482 22.2845)" class="st0" width="21.4" height="1.2"/>
        </g>
    </svg>
</template>

<script>
    export default {
		name: "icon-dev",
        data() {
            return {
                
            }
        }

    }
</script>
