<template>
    <ul class="team-list">
        <li v-for="(member, index) in team" :key="index">
            <div class="team-portrait">
                <img :src="member.photo" :alt="member.name">
            </div>
            <div class="team-detail">
                <h3>
                    <span>{{ member.name }}</span>
                    <div class="line"></div>
                </h3>
                <span class="team-subtitle">
                    <div>{{ member.subtitle }}</div>
                </span>
                <p>
                    {{ member.description }}
                </p>
            </div>
        </li>
    </ul>
</template>

<script>
import Image from "../Objects/Image";
export default {
    name: "team-list",
    data() {
        return {
            team: [
                {
                    name: "Aaron Deckler",
                    subtitle: "Visual Design / UX",
                    photo: new Image("team-aaron.png").getPath(),
                    description: "Aaron has been designing for the web for well over a decade and has work featured in all sorts of pretentious places. He's a man of many hobbies, a ravenous music hunter, and a bit of a freak for Japanese automobiles with deep-dish wheels. His four children care nothing for any of these things."
                },
                {
                    name: "Chris Hammond",
                    subtitle: "Front-end Dev Lead",
                    photo: new Image("team-chris.png").getPath(),
                    description: "Chris has a passion for forward-thinking front-end development. He makes the cool stuffs and wears crazy socks. Chris is also an avid gamer, pixel art dabbler, and a surprisingly good singer/songwriter. He loves cats and recently got a good haircut."
                },
                {
                    name: "Tyler Underwood",
                    subtitle: "Front-end Developer",
                    photo: new Image("team-tyler.png").getPath(),
                    description: "Tyler is somewhat of a CSS savant. He also has a small animal growing on his face and has wrestled a wild hyena (probably not true). He still has his massive Lego collection from childhood, and compensates for his dorkiness by riding a cool motorcycle. Tyler has two cats named Dippy and Dax."
                },
                {
                    name: "Christian Pavilonis",
                    subtitle: "Back-end Developer",
                    photo: new Image("team-christian.png").getPath(),
                    description: "Christian studied back-end development at Hogwarts and insists on being sharply dressed at all times. He's a bit of a quiet guy and grew up in an Amish community. Though he assures us they had electricity – something about living around Amish folks doesn't mean you're Amish. Whatever. Christian wears glasses and does not have a cat."
                },
            ],
        }
    },

    methods: {
       
    },


}
</script>