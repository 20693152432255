export default class SplitText {
    constructor(text) {
        this.text = text;
    }

    get() {
        const text = this.text;
        const arr = text.split(" ");
        const html = "<span><div>" + arr.join("</div></span><span><div>") + "</div></span>"
        return html;
    }
}