<script>
import Image           from '../Objects/Image';
import ScrollRouter    from '../Components/ScrollRouter';
import ProjectDetail   from './ProjectDetail.vue';
import SwatchitProject from '../Objects/Projects/SwatchitProject';

export default {
	extends: ProjectDetail,
	data() {
		return new SwatchitProject()
	},
}

</script>