import { TweenMax }    from "gsap";
import FromToAnimation from "./FromToAnimation";

export default class FadeUpOutAnimation extends FromToAnimation {

    getFrom() {
        return {
            autoAlpha: 1,
            y        : 0,
            delay    : this.delay,
            ease     : Power1.ease,
        }
    }

    getTo() {
        return {
            autoAlpha: 0,
            y        : -40,
            delay    : this.delay,
            ease     : Power1.ease,
        }
    }

}