import ToAnimation from "./ToAnimation";

export default class HideProjectHeaderAnimation extends ToAnimation {

    getTo() {
        return {
            delay: this.delay,
            opacity: 0,
            scaleY: 0,
            width: 0,
            transformOrigin: "top",
            ease: Power4.easeInOut
        }
    }

}

