import Project from "../Project";
import Image from "../Image";
import GodTv from "../../Views/GodTv";

export default class GodTvProject extends Project {
    constructor() {
        super();
        this.title            = "God TV";
        this.route            = { name: "godtv" };
        this.className        = "project-godtv";
        this.wrapper          = "project-godtv-wrapper";
        this.logo             = new Image("work/gtv/work-logo-GTV.svg").getPath();
        this.heroImage        = new Image("work/gtv/work-hero-GTV.jpg").getPath();
        this.scrollScreen     = new Image("work/gtv/scroll-screen-GTV.jpg").getPath();
        this.comps            = new Image("work/gtv/work-comps-GTV.jpg").getPath();

        this.role             = [
            "Visual Design",
            "Branding",
            "WordPress Development",
            "Marketing Automation",
            "Membership System",
        ],
        this.shortDescription = "Design + WordPress Dev";

        this.title1           = "Global Reach";
        this.description1     = "God TV is a global television network reaching millions of people on a daily basis. Although they came to the table with capable in-house team, they needed us to spearhead web initiatives that were consistently being back-burnered. We were able to give them a robust, custom, enterprise-level blog and dramatically improve their web reach.";

        this.title2           = "Dynamic Access";
        this.description2     = "The new blog features various page layouts, including an infinite-scroll feature which dynamically loads the next post on scroll while changing the page URL. Additionally, we created a membership system to promote and sell their daily video devotional product which funds the website. Marketing automation was employed across the two properties, and a system was developed to deliver the daily devotional via email and allow members to access the secure content from an email link without needing to log in.";
        this.component        = GodTv;
    }
}