<script>
import Image            from '../Objects/Image';
import ScrollRouter     from '../Components/ScrollRouter';
import ProjectDetail from './ProjectDetail.vue';
import CityLifeChurchProject from '../Objects/Projects/CityLifeChurchProject';

export default {
    extends: ProjectDetail,
    data() {
        return new CityLifeChurchProject()
    },
}

</script>

<style scoped>

</style>