<template>
	<svg version="1.1" class="icon-marketing" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 32.6 32.7" style="enable-background:new 0 0 32.6 32.7;" xml:space="preserve">
        <g>
            <path class="st0" d="M16.3,12.5c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8S18.4,12.5,16.3,12.5z M16.3,19.1
                c-1.5,0-2.7-1.2-2.7-2.7s1.2-2.7,2.7-2.7c1.5,0,2.7,1.2,2.7,2.7S17.8,19.1,16.3,19.1z"/>
            <path class="st0" d="M32.6,15.8H29C28.7,9.2,23.4,3.9,16.9,3.7V0h-1.1v3.7C9.2,3.9,3.9,9.2,3.7,15.8H0v1.1h3.7
                C3.9,23.4,9.2,28.7,15.8,29v3.7h1.1V29c6.6-0.3,11.8-5.6,12.1-12.1h3.7V15.8z M27.9,15.8h-1.7c-0.3-5-4.3-9.1-9.3-9.3V4.8
                C22.8,5,27.6,9.8,27.9,15.8z M25.1,16.9c-0.3,4.4-3.8,8-8.3,8.3v-0.8h-1.1v0.8c-4.4-0.3-8-3.8-8.3-8.3h0.8v-1.1H7.5
                c0.3-4.4,3.8-8,8.3-8.3v0.8h1.1V7.5c4.4,0.3,8,3.8,8.3,8.3h-0.8v1.1H25.1z M15.8,4.8v1.7c-5,0.3-9.1,4.3-9.3,9.3H4.8
                C5,9.8,9.8,5,15.8,4.8z M4.8,16.9h1.7c0.3,5,4.3,9.1,9.3,9.3v1.7C9.8,27.6,5,22.8,4.8,16.9z M16.9,27.9v-1.7c5-0.3,9.1-4.3,9.3-9.3
                h1.7C27.6,22.8,22.8,27.6,16.9,27.9z"/>
        </g>
    </svg>
</template>

<script>
    export default {
		name: "icon-marketing",
        data() {
            return {
                
            }
        }
    }
</script>
