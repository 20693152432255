<template>
    <div style="position: relative;">
        <div class="main-background-image hero-image">
            <div class="background-image" :style="{ 'background-image': 'url(' + heroImage + ')' }"></div>
        </div>
        <h1 class="main-detail-title">
            <span>{{ title }}</span>
        </h1>
        <div :class="['project-detail', wrapper]">
            <div class="project-detail-inner">
                <div class="screen-view">
                    <div class="bezel">
                        <div class="scroll-image-wrapper">
                            <div class="scroll-image" :style="'background-image: url(' + scrollScreen + ');'"></div>
                        </div>
                        <img class="monitor-base" :src="monitorBase" alt="">
                    </div>
                </div>

                <div class="description-row">
                    <div class="description-text">
                        <h2>{{ title1 }}</h2>
                        <p>{{ description1 }}</p>
                    </div>
                    <div class="logo-wrapper">
                        <img :src="logo" :alt="title + ' Logo'">
                    </div>
                </div>
            </div>

            <div class="comp-row">
                <img class="comp-image" :src="comps" :alt="title">
            </div>

            <div class="project-detail-inner">
                <div class="description-row">
                    <div class="description-text description-text-wide">
                        <h2>{{ title2 }}</h2>
                        <p>{{ description2 }}</p>
                    </div>
                    <div class="bar-list-wrapper">
                        <h3>Our Role</h3>
                        <ul class="bar-list">
                            <li v-for="(item, index) in role" :key="'role-' + index">{{ item }}</li>
                        </ul>
                        <div class="line-1"></div>
                        <div class="line-2"></div>
                        <div class="line-3"></div>
                    </div>
                </div>

                <div class="description-row cta-row">
                    <div class="description-text-wide">
                        <work-button button-text="Let's Talk" route-name="contact"></work-button>
                    </div>
                </div>
            </div>
        </div>
        
        <div :class="['next-hero', nextHeroClass]">
            <div class="background-image" :style="'background-image: url(' + nextHero + ');'"></div>
        </div>
    </div>
</template>

<script>
    import { TweenMax, ScrollToPlugin } from "gsap";
    import ScrollRouter         from "../Components/ScrollRouter";
    import Image                from "../Objects/Image";
    import { setWorkItemWidth, setWorkItemHeight } from "../helpers";
    import { altNav, projects, wipe } from "../Store.js";
    import WorkButton from "../Components/WorkButton";

    import {
        animateHeaderIn,
        animateHeaderOut,
        animateProjectDetailFooterOut,
        animateProjectOut,
        animateProjectIn,
        animateProjectTitleIn,
        animateProjectTitleOut,
    } from "../Objects/Animations/animation-helpers";

    export default {
        extends: ScrollRouter,
        props  : ["previousProject"],
        components: { WorkButton },

        data() {
            return {
                nextProject: {},
                nextHero: null,
                nextHeroClass: "",
                shared: altNav,
                monitorBase: new Image("work/monitor-base.png").getPath(),
            }
        },

        computed: {
            workItemWidth: function() {
                return setWorkItemWidth();
            },

            workItemHeight: function() {
                return setWorkItemHeight();
            },
        },

        methods: {
            setListeners: function() {
                let vm = this;
                window.addEventListener('scroll', vm.doParallax);
                document.getElementById("back-button").addEventListener('click', vm.handleBackButtonClick);
            },

            removeListeners: function() {
                let vm = this;
                window.addEventListener('scroll', vm.doParallax);
                document.getElementById("back-button").removeEventListener('click', vm.handleBackButtonClick);
            },

            setupAltNav: function() {
                this.shared.backButton.route = { name: "work" };
                this.shared.backButton.text = "Back";
                this.shared.nextButton.route = { name: "about" };
                this.shared.nextButton.text = "About";
                this.shared.nextButton.nextHero = new Image("hero-about.jpg").getPath();
            },

            animateElementsIn: function() {
                animateHeaderIn();
                animateProjectTitleIn();
                animateProjectIn();
            },

            animateElementsOut: function() {
                animateProjectTitleOut();
                animateProjectOut();
                animateHeaderOut();
                animateProjectDetailFooterOut();
            },

            handleBackButtonClick: function() {
                TweenMax.to(window, 0.5, {
                    scrollTo: {
                        y: 0,
                        autoKill: false,
                        ease: Power4.ease,
                    },
                    onComplete: this.animateElementsOnBackButtonClick,
                });
            },

            animateElementsOnBackButtonClick: function() {
                this.animateElementsOut();
                this.shrinkHero(0.4);
            },

            goBack: function() {
                this.$router.push(this.shared.backButton.route);
            },

            goNext: function() {
                this.$router.push(this.shared.nextButton.route);
            },

            doParallax: function() {
                let elem = document.querySelector(".hero-image");
                let scrollPosition = window.pageYOffset;
                TweenMax.to(".hero-image", 0.1, {
                    y: -(0.25 * scrollPosition),
                    ease: Power4.ease,
                });

                TweenMax.to(".scroll-image", 0.1, {
                    y: -(0.35 * scrollPosition),
                    ease: Power4.ease,
                });
            },
        
            shrinkHero: function(delay) {
                const elem = ".hero-image";

                let itemWidth = this.workItemWidth;
                let itemHeight = this.workItemHeight;

                TweenMax.fromTo(elem, 0.75, {
                    delay: delay,
                    width: "100vw",
                    height: "100vh",
                    ease: Power4.easeInOut,
                    top: 0,
                    left: 0,
                    transformOrigin: "center",
                },
                {
                    delay: delay,
                    width: itemWidth + "px",
                    height: itemHeight + "px",
                    top: (window.innerHeight / 2) - (itemHeight / 2),
                    left: (window.innerWidth / 2) - (itemWidth / 2),
                    ease: Power4.easeInOut,
                    onComplete: this.goBack
                });

                TweenMax.to(elem + " .background-image", 0.75, {
                    delay: delay,
                    scale: 1.02,
                    ease: Power4.easeInOut,
                });
            },

            getNextHero: function() {
                if(this.nextProject) {
                    return this.nextProject.heroImage;
                }

                return null;
            },

            getNextHeroClass: function() {
                if(this.nextProject) {
                    return this.nextProject.route.name + '-hero';
                }

                return null;
            },
        },

        beforeMount() {
            window.recentProject.latest = projects.selected;
        },

        mounted() {
            projects.selectFromRouteName(this.$route.name);
            
            this.nextProject = projects.getNextProject();
            this.nextHero = this.getNextHero();
            this.nextHeroClass = this.getNextHeroClass();

            this.setListeners();
            this.setupAltNav();
            
            document.querySelector(".main-header").classList.add("alt-header");

            this.$emit("routeChanged", '', this.wrapper + " project-detail-wrapper");
            
            this.nextRoute = this.nextProject.route;

            this.animateElementsIn();
        },

        beforeDestroy() {
            this.removeListeners();
            document.querySelector(".main-header").classList.remove("alt-header");
        }
    }

</script>

<style scoped>

</style>