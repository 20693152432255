<template>
	<svg version="1.1" class="icon-bulb" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 47.9 76.2" enable-background="new 0 0 47.9 76.2" xml:space="preserve">
        <g>
            <path fill="#4563AE" d="M23.9,2.9c11.6,0,21,9.4,21,21c0,7.6-4.1,14.6-10.8,18.4c-1.8,1-3.6,3.6-3.6,7.2c0,1.7,0,5.8,0,8.6H17.4v-9
                c0-3.3-1.9-5.9-3.7-6.9C7,38.5,2.9,31.5,2.9,23.9C2.9,12.4,12.4,2.9,23.9,2.9 M23.9,0C10.7,0,0,10.7,0,23.9c0,9,4.9,16.8,12.2,20.9
                c0.7,0.4,2.2,1.8,2.2,4.3c0,2.5,0,11.9,0,11.9h19c0,0,0-8.8,0-11.5c0-2.7,1.4-4.3,2.1-4.7c7.3-4.1,12.3-11.9,12.3-20.9
                C47.9,10.7,37.2,0,23.9,0L23.9,0z"/>
            <rect x="14.4" y="63.3" fill="#4563AE" width="19.1" height="2.9"/>
            <rect x="14.4" y="68.3" fill="#4563AE" width="19.1" height="2.9"/>
            <rect x="17.3" y="73.3" fill="#4563AE" width="13.2" height="2.9"/>
        </g>
    </svg>
</template>

<script>
    export default {
		name: "icon-bulb",
        data() {
            return {
                
            }
        }

    }
</script>
