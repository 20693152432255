<template>
	<svg version="1.1" class="icon-branding" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 36.3 21.9" style="enable-background:new 0 0 36.3 21.9;" xml:space="preserve">
        <g>
            <g>
                <path class="st0" d="M18.1,21.9c-4.1,0-8.5-1.8-12.7-5.3c-3.2-2.6-5.1-5.2-5.2-5.3L0,10.9l0.2-0.3c0.1-0.1,2-2.7,5.2-5.3
                    C9.7,1.8,14.1,0,18.1,0s8.5,1.8,12.7,5.3C34,7.9,36,10.5,36,10.6l0.2,0.3L36,11.2c-0.1,0.1-2,2.7-5.2,5.3
                    C26.6,20,22.2,21.9,18.1,21.9z M1.3,10.9c1.4,1.7,8.5,9.9,16.8,9.9c8.4,0,15.5-8.2,16.8-9.9C33.6,9.2,26.5,1,18.1,1
                    C9.8,1,2.7,9.2,1.3,10.9z"/>
            </g>
            <g>
                <path class="st0" d="M18.1,18.7c-4.3,0-7.8-3.5-7.8-7.8s3.5-7.8,7.8-7.8s7.8,3.5,7.8,7.8S22.4,18.7,18.1,18.7z M18.1,4.2
                    c-3.7,0-6.8,3-6.8,6.8s3,6.8,6.8,6.8s6.8-3,6.8-6.8S21.9,4.2,18.1,4.2z"/>
            </g>
            <g>
                <path class="st0" d="M18.1,13.3c-1.3,0-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4s2.4,1.1,2.4,2.4S19.4,13.3,18.1,13.3z M18.1,9.6
                    c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3S18.9,9.6,18.1,9.6z"/>
            </g>
        </g>
</svg>

</template>

<script>
    export default {
		name: "icon-branding",
        data() {
            return {
                
            }
        }

    }
</script>
