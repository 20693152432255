<template>
    <nav class="main-nav" role="navigation">
        <ul class="nav-bars">
            <li v-for="(navRoute, index) in navRoutes" :key="'nav-bar-' + index">
                <a :href="'/' + navRoute.route.name" :class="'nav-' + navRoute.route.name" :title="navRoute.title" @click.prevent="handleClick(navRoute.route)">
                    <span class="nav-text">
                        <div>{{ navRoute.route.name }}</div>
                    </span>
                    <span class="bar"></span>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
    import StaggerUpAnimation                         from "../Objects/Animations/StaggerUpAnimation";
    import { wipe }                                   from "../Store";
    import WipeRightAnimation                         from "../Objects/Animations/WipeRightAnimation";
    import { animateBackgroundOut, animateHeaderOut } from "../Objects/Animations/animation-helpers";

    export default {
        name: 'main-nav',
        data() {
            return {
                navRoutes: [
                    {
                        title: "Home",
                        route: { name: "home" }
                    },
                    {
                        title: "About",
                        route: { name: "about" }
                    },
                    {
                        title: "Services",
                        route: { name: "services" }
                    },
                    {
                        title: "Team",
                        route: { name: "team" }
                    }

                ]
            }
        },

        methods: {
            handleClick: function(route) {
                if(route.name === this.$route.name) {
                    return;
                }
                
                wipe.right = route.name;
                new WipeRightAnimation(wipeCallback).animate();

                animateHeaderOut();
                animateBackgroundOut();

                let vm = this;
                function wipeCallback() {
                    vm.changeRoute(route);
                }
            },

            changeRoute: function(route) {
                this.$router.push(route);
            },

            animateNavBars: function() {
                new StaggerUpAnimation(".nav-bars li", 0.5, 0.1, 0.05).animate();
            },
        },

        watch: {
            "$route.path": function() {
                this.animateNavBars();
            }
        },
        
        mounted() {
            this.animateNavBars();
        }

    }

</script>

<style lang="scss" scoped>

</style>