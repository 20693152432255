<template>
    <div class="button-hire-wrapper">
        <button href="/contact" @click.prevent="goToContact">Hire Us<span class="line"></span></button>
    </div>
</template>

<script>
    import MainNav             from "./MainNav";
    import StateLogo           from "./StateLogo";
    import ButtonBack          from "./ButtonBack";
    import WipeUpAnimation     from "../Objects/Animations/WipeUpAnimation";
    import { wipe }            from "../Store";
    import FadeLeftInAnimation from "../Objects/Animations/FadeLeftInAnimation";

    import {
        resetWipeUp,
        animateBackgroundOut,
        animateContentOut,
        animateHeaderOut,
        animateProjectDetailFooterOut
    } from "../Objects/Animations/animation-helpers";

    export default {
        name      : "contact-button",
        components: {
            MainNav,
            StateLogo,
            ButtonBack,
        },

        data() {
            return {}
        },

        mounted() {
            new FadeLeftInAnimation(".button-hire-wrapper", 0.5, 0.5).animate();
        },

        methods: {
            goToContact: function() {
                wipe.up = "contact";
                let vm = this;

                animateHeaderOut();
                animateProjectDetailFooterOut();

                animateContentOut(vm.$route.name);

                if(this.$route.fullPath.indexOf("work") === -1) {
                    animateBackgroundOut();
                }

                new WipeUpAnimation(this.changeRoute).animate();
            },

            changeRoute: function() {
                this.$router.push({ name: "contact" });
            }
        },

    }

</script>

<style lang="scss" scoped>

</style>