import { TweenMax }     from "gsap";
import StaggerAnimation from "./StaggerAnimation";

export default class StaggerWorkItemsAnimation extends StaggerAnimation {

    getFrom() {
        return {
            delay  : this.delay,
            opacity: 0,
            x      : 600,
            scale  : 0.75,
            ease   : Power3.easeOut,
        }
    }

    getTo() {
        return {
            delay  : this.delay,
            opacity: 1,
            x      : 0,
            scale  : 1,
            ease   : Power3.easeOut,
        };
    }

}