import ToAnimation from "./ToAnimation";

export default class ScrollToProjectAnimation extends ToAnimation {

    setOfsetX(offsetX) {
        this.offsetX = offsetX;
    }

    setProject(project) {
        this.project = project;
    }

    getTo() {
        return {
            ease    : Power1.easeInOut,
            scrollTo: {
                x      : this.project,
                offsetX: this.offsetX,
            }
        }
    }

}