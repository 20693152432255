<script>
    import ScrollRouter              from "../Components/ScrollRouter";
    import HeaderInAnimationSuite    from "../Objects/Animations/Suites/HeaderInAnimationSuite";
    import { wipe }                  from "../Store";                   

    import {
        animateBackgroundIn,
        animateBackgroundOut,
        animateContentOut,
        animateHeadingTextIn,
        animateHeadingTextOut,
        animateHeaderIn,
        animateHeaderOut,
        resetWipeRight,
        resetWipeLeft,
        animateProjectDetailFooterOut
    } from "../Objects/Animations/animation-helpers";

    export default {
        name: "PresentationView",
        extends: ScrollRouter,

        mounted() {
            animateBackgroundIn();
            animateHeaderIn();
            
            if(this.hasBigHeading()) {
                animateHeadingTextIn();
            }
        },

        methods: {
            animateElementsOut: function() {
                let vm = this;
                animateContentOut(vm.$route.name);
                animateBackgroundOut();
                animateHeaderOut();
                animateProjectDetailFooterOut();

                if(this.hasBigHeading()) {
                    animateHeadingTextOut()
                }

            },
            
            hasBigHeading() {
                return document.querySelectorAll(".heading-big span").length > 0;
            }
        }

    }
</script>