import Animation       from "./Animation";
import { TweenMax }    from "gsap";
import FromToAnimation from "./FromToAnimation";

export default class StaggerAnimation extends FromToAnimation {

    constructor(el, timing, delay, staggerDelay = 0.05) {
        super(el, timing, delay);
        this.staggerDelay = staggerDelay

    }


    animate() {
        TweenMax.staggerFromTo(this.el, this.timing, this.getFrom(), this.getTo(), this.staggerDelay);
    }


}