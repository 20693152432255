import { TweenMax, TimelineLite } from 'gsap';

export default class Animation {

    constructor(el, timing, delay = 0, callback = null) {
        this.el = el;
        this.timing = timing;
        this.delay = delay;
        this.callback = callback;
    }

}