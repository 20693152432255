<script>
    import ProjectDetail from './ProjectDetail.vue';
    import SerenityProject from '../Objects/Projects/SerenityProject';

    export default {
        extends: ProjectDetail,

        data() {
            return new SerenityProject()
        }
    }

</script>

<style scoped>

</style>