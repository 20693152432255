<template>
	<div class="badge-logo">
        <svg version="1.1" class="badge-logo-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 133.3 116.3" style="enable-background:new 0 0 133.3 116.3;" xml:space="preserve">
            <g>
                <g>
                    <path class="st0" d="M59.4,35.3l-0.3-0.5h-1.5l-0.3,0.5H57l1.2-2h0.5l1.2,2H59.4z M58.4,33.6l-0.6,1h1.1L58.4,33.6z"/>
                    <path class="st0" d="M62.2,35.4c-0.8,0-1.2-0.3-1.2-0.7v-1.4h0.4v1.3c0,0.3,0.3,0.5,0.9,0.5c0.6,0,0.9-0.2,0.9-0.5v-1.3h0.4v1.4
                        C63.5,35.1,63,35.4,62.2,35.4z"/>
                    <path class="st0" d="M66,33.5c-0.5,0-0.7,0.1-0.7,0.3c0,0.1,0.1,0.2,0.2,0.2c0.2,0.1,0.6,0.1,1,0.1c0.5,0.1,0.8,0.2,0.8,0.6
                        c0,0.4-0.4,0.7-1.2,0.7c-0.6,0-1-0.1-1.3-0.3l0.2-0.2c0.4,0.2,0.7,0.3,1.1,0.3c0.5,0,0.8-0.1,0.8-0.4c0-0.3-0.4-0.3-1-0.3
                        c-0.7,0-1-0.1-1-0.5c0-0.4,0.4-0.6,1.1-0.6c0.5,0,0.9,0.1,1.2,0.3L67,33.8C66.7,33.6,66.4,33.5,66,33.5z"/>
                    <path class="st0" d="M69.8,33.6v1.7h-0.4v-1.7h-1v-0.3h2.5v0.3H69.8z"/>
                    <path class="st0" d="M72.1,35.3v-2h0.4v2H72.1z"/>
                    <path class="st0" d="M76.1,35.3l-1.7-1.7v1.7h-0.4v-2h0.5l1.7,1.7v-1.7h0.4v2H76.1z"/>
                </g>
                <g>
                    <path class="st0" d="M59.3,81.3V83h-0.4v-1.7h-1V81h2.5v0.3H59.3z"/>
                    <path class="st0" d="M61.6,83v-2h2.2v0.3H62v0.5h1.3v0.3H62v0.6h1.9V83H61.6z"/>
                    <path class="st0" d="M67.3,83l-0.9-0.8L65.6,83h-0.5l1.1-1l-1.1-1h0.5l0.8,0.8l0.8-0.8h0.5l-1.1,1l1.1,1H67.3z"/>
                    <path class="st0" d="M71.2,83L71,82.5h-1.5L69.2,83h-0.4l1.2-2h0.5l1.2,2H71.2z M70.2,81.3l-0.6,1h1.1L70.2,81.3z"/>
                    <path class="st0" d="M73.8,81.2c-0.5,0-0.7,0.1-0.7,0.3c0,0.1,0.1,0.2,0.2,0.2c0.2,0.1,0.6,0.1,1,0.1c0.5,0.1,0.8,0.2,0.8,0.6
                        c0,0.4-0.4,0.7-1.2,0.7c-0.6,0-1-0.1-1.3-0.3l0.2-0.2c0.4,0.2,0.7,0.3,1.1,0.3c0.5,0,0.8-0.1,0.8-0.4c0-0.3-0.4-0.3-1-0.3
                        c-0.7,0-1-0.1-1-0.5c0-0.4,0.4-0.6,1.1-0.6c0.5,0,0.9,0.1,1.2,0.3l-0.2,0.2C74.4,81.3,74.1,81.2,73.8,81.2z"/>
                </g>
                <g>
                    <path class="st0" d="M63.9,49.2c1.2,0,2.8-0.8,3.4-1.9l2.7-4.2c0.7-1,0.2-1.9-1-1.9H47c-1.2,0-2.8,0.9-3.4,1.9l-2.7,4.2
                        c-0.7,1-0.2,1.9,1,1.9h1c1.2,0,1.7,0.8,1,1.9l-8.5,13.4c-0.7,1-1.8,1.1-2.5,0L23.9,51c-0.7-1-0.3-1.8,1-1.8h5.9
                        c1.2,0,2.8-0.9,3.4-1.9l2.7-4.2c0.7-1,0.2-1.9-1-1.9H20.5c-1.2,0-2.8,0.8-3.4,1.9l-2.7,4.3c-0.7,1-0.6,2.7,0.1,3.7L24,65.2
                        c0.7,1,0.3,1.8-1,1.8L6.5,67c-1.2,0-2.8,0.8-3.4,1.9l-2.7,4.3c-0.7,1-0.2,1.9,1,1.9h26.1c1.2,0,2.2,0,2.2,0c0,0,1,0,2.2,0l4.3,0
                        c1.2,0,2.8-0.9,3.4-1.9l14-22c0.7-1,2.2-1.9,3.4-1.9H63.9z"/>
                    <path class="st0" d="M126.8,49.2c1.2,0,2.8-0.8,3.4-1.9l2.7-4.2c0.7-1,0.2-1.9-1-1.9l-50.7,0c-1.2,0-2.8,0.8-3.4,1.9l-2.7,4.3
                        c-0.7,1-0.2,1.9,1,1.9h8.3c1.2,0,1.7,0.9,1.1,1.9l-6.3,10.2c-0.6,1-1.7,1-2.3,0l-4.4-7.2c-0.6-1-2.2-1.9-3.4-1.9h-7.5
                        c-1.2,0-2.8,0.9-3.4,1.9L46.6,73.2c-0.6,1-0.2,1.9,1.1,1.9l5.1,0c1.2,0,2.8-0.9,3.4-1.9l8-13.2c0.6-1,1.7-1,2.3,0l3.8,6.3
                        c0.6,1,0.2,1.9-1.1,1.9l-3.2,0c-1.2,0-1.6,0.8-0.9,1.8l2.5,3.3c0.7,1,2.3,1.8,3.6,1.8l11.8,0c1.2,0,1.7-0.9,1.1-1.9l-0.3-0.6
                        c-0.6-1-0.6-2.8,0-3.8l11.2-17.7c0.7-1,2.2-1.9,3.4-1.9h2.4c1.2,0,1.7,0.9,1,1.9L87.9,73.2c-0.7,1-0.2,1.9,1,1.9h22.9
                        c1.2,0,2.8-0.8,3.4-1.9l2.7-4.3c0.7-1,0.2-1.9-1-1.9l-13.4,0c-1.2,0-1.7-0.8-1-1.9l0.5-0.7c0.7-1,2.2-1.9,3.4-1.9l0.9,0
                        c1.2,0,2.8-0.8,3.4-1.9l2.7-4.2c0.7-1,0.2-1.9-1-1.9l-1.1,0c-1.2,0-1.7-0.8-1-1.9l1-1.5c0.7-1,2.2-1.9,3.4-1.9L126.8,49.2z"/>
                </g>
                <g>
                    <path class="st0" d="M37.4,8.5c-0.7,0.4-1,0.8-0.9,1.1c0.1,0.1,0.2,0.2,0.5,0.1c0.5-0.1,1-0.5,1.8-0.8c0.9-0.4,1.5-0.4,1.8,0.2
                        c0.4,0.7,0,1.5-1.3,2.2c-0.9,0.5-1.7,0.8-2.4,0.7l0.1-0.6c0.7,0,1.4-0.2,2-0.6c0.8-0.5,1.1-1,0.9-1.3c-0.3-0.5-0.9-0.1-1.9,0.4
                        C37,10.5,36.3,10.6,36,10c-0.4-0.6,0.1-1.4,1.2-2c0.8-0.4,1.5-0.7,2.2-0.6l-0.1,0.6C38.5,8,38,8.2,37.4,8.5z"/>
                    <path class="st0" d="M43.9,5.3l1.3,2.9l-0.6,0.3l-1.3-2.9l-1.8,0.8l-0.2-0.5L45.4,4l0.2,0.5L43.9,5.3z"/>
                    <path class="st0" d="M52.2,5.7L51.5,5l-2.6,0.8l-0.2,0.9L48,7l0.9-4.2l0.8-0.3l3.2,2.9L52.2,5.7z M49.5,3.2l-0.5,2l2-0.6L49.5,3.2
                        z"/>
                    <path class="st0" d="M56,1.6l0.6,3.1l-0.7,0.1l-0.6-3.1l-1.9,0.4l-0.1-0.6l4.5-0.9l0.1,0.6L56,1.6z"/>
                    <path class="st0" d="M60.8,4l-0.3-3.7L64.6,0l0,0.6l-3.4,0.2l0.1,1l2.4-0.2l0,0.6l-2.4,0.2l0.1,1.1L65,3.2l0,0.6L60.8,4z"/>
                    <path class="st0" d="M79.1,4.1C79,4.7,78.4,5,77.4,4.8l-3.2-0.6l0.7-3.7l3.2,0.6c0.8,0.2,1.2,0.6,1.1,1.2
                        c-0.1,0.3-0.3,0.6-0.7,0.7C79,3.3,79.2,3.6,79.1,4.1z M78.5,3.9c0.1-0.4-0.1-0.6-0.6-0.7l-2.6-0.5l-0.2,1l2.5,0.5
                        C78.1,4.4,78.4,4.3,78.5,3.9z M78.6,2.3c0.1-0.3-0.1-0.5-0.5-0.6l-2.6-0.5l-0.2,1l2.6,0.5C78.2,2.7,78.6,2.6,78.6,2.3z"/>
                    <path class="st0" d="M83.7,6.6c-1.4-0.4-2-1.3-1.8-2l0.8-2.4l0.6,0.2l-0.8,2.3c-0.2,0.6,0.2,1,1.3,1.4c1,0.3,1.6,0.2,1.8-0.3
                        l0.8-2.3l0.6,0.2l-0.8,2.4C86.1,6.8,85.1,7,83.7,6.6z"/>
                    <path class="st0" d="M88.6,8.4L90.2,5l0.6,0.3l-1.5,3.4L88.6,8.4z"/>
                    <path class="st0" d="M91.9,9.9l1.8-3.3L94.3,7l-1.6,2.8l2.7,1.5l-0.3,0.5L91.9,9.9z"/>
                    <path class="st0" d="M99.7,11l-1.8,2.6l-0.6-0.4l1.8-2.6l-1.6-1.1L97.9,9l3.7,2.6l-0.3,0.5L99.7,11z"/>
                </g>
                <g>
                    <path class="st0" d="M122.6,58c0,11.8-3.7,22.8-9.9,31.8l0.4,0.2c6.3-9.1,10-20.2,10-32.1c0-17.4-7.9-33-20.3-43.4l-0.3,0.3
                        C114.7,25.3,122.6,40.7,122.6,58z"/>
                    <path class="st0" d="M20.6,90.2C14.2,81.1,10.4,70,10.4,58c0-18.6,9.1-35.2,23.2-45.4l-0.3-0.3C19.2,22.6,10,39.2,10,58
                        c0,12.1,3.8,23.3,10.3,32.5L20.6,90.2z"/>
                </g>
                <g>
                    <path class="st0" d="M24.8,99.2l-0.6-0.6l1.6-2.9l-3,1.4l-0.6-0.6l1.8-3.5l0.5,0.6L23,96.3l3-1.4l0.6,0.6L25,98.4l3-1.4l0.5,0.6
                        L24.8,99.2z"/>
                    <path class="st0" d="M27.9,102l2.4-2.9l3.2,2.6l-0.4,0.4l-2.7-2.2l-0.6,0.7l1.9,1.5l-0.4,0.4l-1.9-1.5l-0.7,0.9l2.8,2.3l-0.4,0.4
                        L27.9,102z"/>
                    <path class="st0" d="M41.3,109.7c-0.3,0.6-0.9,0.6-1.8,0.1l-2.9-1.5l1.8-3.3l2.9,1.6c0.7,0.4,1,1,0.7,1.5
                        c-0.2,0.3-0.4,0.5-0.8,0.4C41.4,108.9,41.5,109.3,41.3,109.7z M40.7,109.3c0.2-0.3,0-0.6-0.4-0.8l-2.4-1.3l-0.5,0.9l2.3,1.2
                        C40.2,109.6,40.5,109.6,40.7,109.3z M41.3,107.8c0.1-0.3,0-0.6-0.3-0.7l-2.3-1.3l-0.5,0.9l2.3,1.2
                        C40.9,108.1,41.2,108.1,41.3,107.8z"/>
                    <path class="st0" d="M45.8,112.8c-1.3-0.5-2-1.3-1.7-2.1l0.9-2.4l0.6,0.2l-0.8,2.2c-0.2,0.5,0.2,1,1.2,1.4c1,0.4,1.6,0.3,1.8-0.3
                        l0.8-2.2l0.6,0.2l-0.9,2.4C48.1,113,47.1,113.3,45.8,112.8z"/>
                    <path class="st0" d="M51.1,114.3l1-3.6l0.7,0.2l-1,3.6L51.1,114.3z"/>
                    <path class="st0" d="M54.9,115.2l0.6-3.7l0.7,0.1l-0.5,3.1l3.1,0.5l-0.1,0.6L54.9,115.2z"/>
                    <path class="st0" d="M66.2,114.6c0,0.5-0.3,1-0.7,1.3c-0.4,0.3-0.9,0.5-1.5,0.4l-2.7-0.1l0.2-3.7l2.6,0.1
                        C65.4,112.6,66.3,113.4,66.2,114.6z M65.5,114.5c0-0.8-0.5-1.4-1.5-1.4l-2-0.1l-0.1,2.6l2.1,0.1C64.9,115.8,65.5,115.3,65.5,114.5
                        z"/>
                    <path class="st0" d="M74.3,112.6l0.4,3.2l-0.7,0.1l-0.4-3.2l-1.9,0.3l-0.1-0.6l4.5-0.6l0.1,0.6L74.3,112.6z"/>
                    <path class="st0" d="M83.1,113.9l-0.4-1.6l-3.2,0.9l0.4,1.6l-0.7,0.2l-1-3.6l0.7-0.2l0.4,1.5l3.2-0.9l-0.4-1.5l0.7-0.2l1,3.6
                        L83.1,113.9z"/>
                    <path class="st0" d="M86.9,112.6l-1.4-3.5l3.8-1.6l0.2,0.5l-3.2,1.3l0.4,0.9l2.2-0.9l0.2,0.5l-2.2,0.9l0.4,1l3.3-1.4l0.2,0.5
                        L86.9,112.6z"/>
                    <path class="st0" d="M100.4,105.4l-0.7,0.5l-2.6-2L98,107l-0.7,0.5l-3.2-2.4l0.6-0.4l2.6,2l-0.9-3.2l0.7-0.5l2.6,2l-0.9-3.2
                        l0.6-0.4L100.4,105.4z"/>
                    <path class="st0" d="M103.7,102.9l-2.5-2.8l3.1-2.7l0.4,0.4l-2.6,2.3l0.6,0.7l1.8-1.6l0.4,0.4l-1.8,1.6l0.7,0.8l2.7-2.4l0.4,0.4
                        L103.7,102.9z"/>
                    <path class="st0" d="M111.4,93.6c0.5,0.4,0.4,1-0.3,1.8l-2.1,2.5l-2.8-2.5l2.2-2.5c0.6-0.6,1.2-0.7,1.6-0.3
                        c0.3,0.2,0.3,0.5,0.3,0.9C110.6,93.3,111,93.3,111.4,93.6z M109.6,93.1c-0.2-0.2-0.5-0.1-0.8,0.1l-1.7,2l0.8,0.7l1.7-2
                        C109.8,93.7,109.8,93.3,109.6,93.1z M110.9,94.1c-0.3-0.2-0.6-0.2-0.9,0.2l-1.8,2L109,97l1.7-1.9
                        C111.1,94.6,111.1,94.3,110.9,94.1z"/>
                </g>
                <g>
                    <path class="st0" d="M91.4,78.6c-5.9,7.1-14.8,11.7-24.7,11.7S47.9,85.7,42,78.6h-1.1c6,7.6,15.4,12.6,25.8,12.6
                        s19.8-4.9,25.8-12.6H91.4z"/>
                    <path class="st0" d="M41.7,38.3c5.9-7.3,14.9-12.1,25-12.1s19.1,4.7,25,12.1h1.1c-6-7.8-15.5-12.9-26.1-12.9s-20.1,5.1-26.1,12.9
                        H41.7z"/>
                </g>
                <g>
                    <path class="st0" d="M73,96.9L72.6,97l0.3,2c0.1,0,0.3,0,0.4-0.1L73,96.9z"/>
                    <path class="st0" d="M77.8,20.5l0.6-2.2c-0.1,0-0.3-0.1-0.4-0.1l-0.6,2.2L77.8,20.5z"/>
                    <path class="st0" d="M74.1,96.7l-0.4,0.1l0.4,2c0.1,0,0.3,0,0.4-0.1L74.1,96.7z"/>
                    <path class="st0" d="M70.7,97.2c-0.1,0-0.2,0-0.4,0l0.2,2c0.1,0,0.3,0,0.4,0L70.7,97.2z"/>
                    <path class="st0" d="M78.6,95.6l-0.4,0.1l0.6,1.9c0.1,0,0.3-0.1,0.4-0.1L78.6,95.6z"/>
                    <path class="st0" d="M77.5,95.9c-0.1,0-0.2,0.1-0.4,0.1l0.5,2c0.1,0,0.3-0.1,0.4-0.1L77.5,95.9z"/>
                    <path class="st0" d="M79.7,95.2c-0.1,0-0.2,0.1-0.4,0.1l0.6,1.9c0.1,0,0.2-0.1,0.4-0.1L79.7,95.2z"/>
                    <path class="st0" d="M66.1,18.9l0.4,0l0-2.3c-0.1,0-0.3,0-0.4,0L66.1,18.9z"/>
                    <path class="st0" d="M76.4,96.2L76,96.3l0.5,2c0.1,0,0.3-0.1,0.4-0.1L76.4,96.2z"/>
                    <path class="st0" d="M74.5,19.7l0.5-2.3c-0.1,0-0.3-0.1-0.4-0.1l-0.4,2.3L74.5,19.7z"/>
                    <path class="st0" d="M75.6,19.9l0.5-2.2c-0.1,0-0.3-0.1-0.4-0.1l-0.5,2.2C75.3,19.8,75.5,19.9,75.6,19.9z"/>
                    <path class="st0" d="M76.7,20.2l0.6-2.2c-0.1,0-0.3-0.1-0.4-0.1l-0.6,2.2L76.7,20.2z"/>
                    <path class="st0" d="M68.7,18.9l0.1-2.3c-0.1,0-0.3,0-0.4,0l-0.1,2.3L68.7,18.9z"/>
                    <path class="st0" d="M73.3,19.4l0.4-2.3c-0.1,0-0.3,0-0.4-0.1L73,19.4L73.3,19.4z"/>
                    <path class="st0" d="M72.2,19.3l0.3-2.3c-0.1,0-0.3,0-0.4-0.1l-0.3,2.3L72.2,19.3z"/>
                    <path class="st0" d="M69.9,19l0.2-2.3c-0.1,0-0.3,0-0.4,0L69.5,19L69.9,19z"/>
                    <path class="st0" d="M71,19.1l0.3-2.3c-0.1,0-0.3,0-0.4,0l-0.2,2.3L71,19.1z"/>
                    <path class="st0" d="M71.8,97.1c-0.1,0-0.2,0-0.4,0l0.2,2c0.1,0,0.3,0,0.4,0L71.8,97.1z"/>
                    <path class="st0" d="M80.7,94.8c-0.1,0-0.2,0.1-0.3,0.1l0.7,1.9c0.1,0,0.2-0.1,0.4-0.1L80.7,94.8z"/>
                    <path class="st0" d="M91.6,88.5l-0.3,0.2l1.3,1.6c0.1-0.1,0.2-0.2,0.3-0.3L91.6,88.5z"/>
                    <path class="st0" d="M92.5,87.7L92.2,88l1.3,1.5c0.1-0.1,0.2-0.2,0.3-0.3L92.5,87.7z"/>
                    <path class="st0" d="M90.7,89.2c-0.1,0.1-0.2,0.2-0.3,0.2l1.2,1.6c0.1-0.1,0.2-0.2,0.3-0.2L90.7,89.2z"/>
                    <path class="st0" d="M94.7,85.6l1.5,1.4c0.1-0.1,0.2-0.2,0.3-0.3L95,85.3L94.7,85.6z"/>
                    <path class="st0" d="M67.6,18.9l0.1-2.3c-0.1,0-0.3,0-0.4,0l0,2.3L67.6,18.9z"/>
                    <path class="st0" d="M94.2,86.1l-0.3,0.3l1.4,1.5c0.1-0.1,0.2-0.2,0.3-0.3L94.2,86.1z"/>
                    <path class="st0" d="M89.8,89.9l-0.3,0.2l1.2,1.7c0.1-0.1,0.2-0.2,0.3-0.2L89.8,89.9z"/>
                    <path class="st0" d="M93.4,86.9l-0.3,0.3l1.4,1.5c0.1-0.1,0.2-0.2,0.3-0.3L93.4,86.9z"/>
                    <path class="st0" d="M82.9,93.9l-0.4,0.2l0.8,1.9c0.1-0.1,0.2-0.1,0.4-0.2L82.9,93.9z"/>
                    <path class="st0" d="M84.9,92.9l-0.3,0.2l0.9,1.8c0.1-0.1,0.2-0.1,0.4-0.2L84.9,92.9z"/>
                    <path class="st0" d="M83.9,93.4c-0.1,0.1-0.2,0.1-0.3,0.2l0.9,1.8c0.1-0.1,0.2-0.1,0.4-0.2L83.9,93.4z"/>
                    <path class="st0" d="M81.8,94.4l-0.4,0.2l0.8,1.9c0.1-0.1,0.3-0.1,0.4-0.2L81.8,94.4z"/>
                    <path class="st0" d="M88.9,90.5l-0.3,0.2l1.1,1.7c0.1-0.1,0.2-0.2,0.3-0.2L88.9,90.5z"/>
                    <path class="st0" d="M85.9,92.4l-0.3,0.2l1,1.8c0.1-0.1,0.2-0.1,0.4-0.2L85.9,92.4z"/>
                    <path class="st0" d="M87.9,91.2c-0.1,0.1-0.2,0.1-0.3,0.2l1.1,1.7c0.1-0.1,0.2-0.1,0.3-0.2L87.9,91.2z"/>
                    <path class="st0" d="M86.9,91.8c-0.1,0.1-0.2,0.1-0.3,0.2l1,1.7c0.1-0.1,0.2-0.1,0.3-0.2L86.9,91.8z"/>
                    <path class="st0" d="M75.2,96.5l-0.4,0.1l0.4,2c0.1,0,0.3-0.1,0.4-0.1L75.2,96.5z"/>
                    <path class="st0" d="M100,78.9l-0.2,0.3l1.7,1.1c0.1-0.1,0.1-0.2,0.2-0.3L100,78.9z"/>
                    <path class="st0" d="M78.9,20.8l0.7-2.2c-0.1,0-0.3-0.1-0.4-0.1l-0.7,2.2L78.9,20.8z"/>
                    <path class="st0" d="M98,81.8l-0.2,0.3l1.6,1.3c0.1-0.1,0.2-0.2,0.2-0.3L98,81.8z"/>
                    <path class="st0" d="M101.6,78.6h-0.8l1.3,0.8c0.1-0.1,0.1-0.2,0.2-0.4L101.6,78.6z"/>
                    <path class="st0" d="M95.3,31.3l1.6-1.5c-0.1-0.1-0.2-0.2-0.3-0.3L95,31L95.3,31.3z"/>
                    <path class="st0" d="M69.2,97.3l0.1,2c0.1,0,0.3,0,0.4,0l-0.1-2C69.4,97.3,69.3,97.3,69.2,97.3z"/>
                    <path class="st0" d="M100.7,38.3h0.8l1.2-0.7c-0.1-0.1-0.1-0.2-0.2-0.3L100.7,38.3z"/>
                    <path class="st0" d="M97.3,82.7L97,83l1.6,1.3c0.1-0.1,0.2-0.2,0.3-0.3L97.3,82.7z"/>
                    <path class="st0" d="M98.2,34.8l1.8-1.3c-0.1-0.1-0.2-0.2-0.2-0.3L98,34.5L98.2,34.8z"/>
                    <path class="st0" d="M99.6,36.7l1.9-1.2c-0.1-0.1-0.2-0.2-0.2-0.3l-1.9,1.2L99.6,36.7z"/>
                    <path class="st0" d="M98.1,84.9l-1.6-1.3l-0.2,0.3l1.5,1.3C97.9,85.1,98,85,98.1,84.9z"/>
                    <path class="st0" d="M100.2,37.7l1.9-1.2c-0.1-0.1-0.1-0.2-0.2-0.3l-1.9,1.2L100.2,37.7z"/>
                    <path class="st0" d="M68,97.4l0.1,2c0.1,0,0.3,0,0.4,0l-0.1-2C68.3,97.4,68.1,97.4,68,97.4z"/>
                    <path class="st0" d="M98.9,35.8l1.8-1.3c-0.1-0.1-0.2-0.2-0.2-0.3l-1.8,1.3L98.9,35.8z"/>
                    <path class="st0" d="M97.5,33.9l1.8-1.4c-0.1-0.1-0.2-0.2-0.3-0.3l-1.7,1.4L97.5,33.9z"/>
                    <path class="st0" d="M96.1,32.1l1.7-1.5c-0.1-0.1-0.2-0.2-0.3-0.3l-1.7,1.5L96.1,32.1z"/>
                    <path class="st0" d="M96.8,33l1.7-1.4c-0.1-0.1-0.2-0.2-0.3-0.3l-1.7,1.5L96.8,33z"/>
                    <path class="st0" d="M98.7,80.9l-0.2,0.3l1.7,1.2c0.1-0.1,0.2-0.2,0.2-0.3L98.7,80.9z"/>
                    <path class="st0" d="M66.8,97.4l0,2c0.1,0,0.3,0,0.4,0l0-2L66.8,97.4z"/>
                    <path class="st0" d="M85.3,23.6l1.1-2c-0.1-0.1-0.2-0.1-0.4-0.2l-1.1,2C85.1,23.4,85.2,23.5,85.3,23.6z"/>
                    <path class="st0" d="M83.2,22.5l1-2.1c-0.1-0.1-0.2-0.1-0.4-0.2l-0.9,2.1L83.2,22.5z"/>
                    <path class="st0" d="M86.3,24.1l1.1-2c-0.1-0.1-0.2-0.1-0.3-0.2l-1.1,2C86.1,24,86.2,24.1,86.3,24.1z"/>
                    <path class="st0" d="M81.1,21.6l0.8-2.1c-0.1-0.1-0.3-0.1-0.4-0.1l-0.8,2.1L81.1,21.6z"/>
                    <path class="st0" d="M80,21.2l0.8-2.2c-0.1,0-0.3-0.1-0.4-0.1l-0.8,2.2L80,21.2z"/>
                    <path class="st0" d="M82.2,22.1l0.9-2.1c-0.1-0.1-0.3-0.1-0.4-0.2l-0.9,2.1L82.2,22.1z"/>
                    <path class="st0" d="M87.3,24.7l1.2-1.9c-0.1-0.1-0.2-0.1-0.3-0.2L87,24.5C87.1,24.6,87.2,24.7,87.3,24.7z"/>
                    <path class="st0" d="M84.2,23l1-2c-0.1-0.1-0.2-0.1-0.4-0.2l-1,2C84,22.9,84.1,23,84.2,23z"/>
                    <path class="st0" d="M88.3,25.4l1.2-1.9c-0.1-0.1-0.2-0.2-0.3-0.2l-1.2,1.9L88.3,25.4z"/>
                    <path class="st0" d="M92,28.1l1.5-1.7c-0.1-0.1-0.2-0.2-0.3-0.3l-1.4,1.7L92,28.1z"/>
                    <path class="st0" d="M92.8,28.9l1.5-1.7c-0.1-0.1-0.2-0.2-0.3-0.3l-1.5,1.7L92.8,28.9z"/>
                    <path class="st0" d="M94.5,30.5l1.6-1.6c-0.1-0.1-0.2-0.2-0.3-0.3l-1.6,1.6L94.5,30.5z"/>
                    <path class="st0" d="M93.7,29.7l1.5-1.6c-0.1-0.1-0.2-0.2-0.3-0.3l-1.5,1.6L93.7,29.7z"/>
                    <path class="st0" d="M89.2,26l1.3-1.9c-0.1-0.1-0.2-0.2-0.3-0.2l-1.3,1.9L89.2,26z"/>
                    <path class="st0" d="M90.1,26.7l1.4-1.8c-0.1-0.1-0.2-0.2-0.3-0.2l-1.3,1.8L90.1,26.7z"/>
                    <path class="st0" d="M91.1,27.4l1.4-1.8c-0.1-0.1-0.2-0.2-0.3-0.2l-1.4,1.8L91.1,27.4z"/>
                    <path class="st0" d="M99.3,79.9l-0.2,0.3l1.7,1.2c0.1-0.1,0.2-0.2,0.2-0.3L99.3,79.9z"/>
                    <path class="st0" d="M44.1,90.3l-1.2,1.7c0.1,0.1,0.2,0.2,0.3,0.2l1.2-1.7L44.1,90.3z"/>
                    <path class="st0" d="M44.4,25.8l0.3-0.2l-1.3-1.9c-0.1,0.1-0.2,0.2-0.3,0.2L44.4,25.8z"/>
                    <path class="st0" d="M42.2,88.9L41,90.5c0.1,0.1,0.2,0.2,0.3,0.2l1.3-1.6L42.2,88.9z"/>
                    <path class="st0" d="M41.3,88.2L40,89.7c0.1,0.1,0.2,0.2,0.3,0.3l1.3-1.6L41.3,88.2z"/>
                    <path class="st0" d="M45,90.9l-1.1,1.7c0.1,0.1,0.2,0.1,0.3,0.2l1.1-1.7L45,90.9z"/>
                    <path class="st0" d="M46,91.6l-1.1,1.7c0.1,0.1,0.2,0.1,0.3,0.2l1.1-1.7L46,91.6z"/>
                    <path class="st0" d="M48,92.7l-1,1.8c0.1,0.1,0.2,0.1,0.4,0.2l0.9-1.8L48,92.7z"/>
                    <path class="st0" d="M49,93.2l-0.9,1.8c0.1,0.1,0.2,0.1,0.4,0.2l0.9-1.8L49,93.2z"/>
                    <path class="st0" d="M47,92.1l-1,1.8c0.1,0.1,0.2,0.1,0.4,0.2l1-1.8L47,92.1z"/>
                    <path class="st0" d="M39.6,86.6l-1.4,1.5c0.1,0.1,0.2,0.2,0.3,0.3l1.4-1.5L39.6,86.6z"/>
                    <path class="st0" d="M41.7,27.8l0.3-0.2l-1.4-1.8c-0.1,0.1-0.2,0.2-0.3,0.3L41.7,27.8z"/>
                    <path class="st0" d="M42.6,27.1l0.3-0.2l-1.4-1.8c-0.1,0.1-0.2,0.2-0.3,0.2L42.6,27.1z"/>
                    <path class="st0" d="M40.8,28.6l0.3-0.3l-1.5-1.7c-0.1,0.1-0.2,0.2-0.3,0.3L40.8,28.6z"/>
                    <path class="st0" d="M43.5,26.4l0.3-0.2l-1.3-1.8c-0.1,0.1-0.2,0.2-0.3,0.2L43.5,26.4z"/>
                    <path class="st0" d="M50.1,93.7l-0.9,1.8c0.1,0.1,0.2,0.1,0.4,0.2l0.8-1.8L50.1,93.7z"/>
                    <path class="st0" d="M39.9,29.4l0.3-0.3l-1.5-1.7c-0.1,0.1-0.2,0.2-0.3,0.3L39.9,29.4z"/>
                    <path class="st0" d="M40.5,87.4l-1.4,1.5c0.1,0.1,0.2,0.2,0.3,0.3l1.3-1.5C40.7,87.6,40.6,87.5,40.5,87.4z"/>
                    <path class="st0" d="M39.4,29.9l-1.6-1.6c-0.1,0.1-0.2,0.2-0.3,0.3l1.6,1.6L39.4,29.9z"/>
                    <path class="st0" d="M43.2,89.6l-1.2,1.6c0.1,0.1,0.2,0.2,0.3,0.2l1.2-1.6L43.2,89.6z"/>
                    <path class="st0" d="M63.4,97.3l-0.2,2c0.1,0,0.3,0,0.4,0l0.1-2L63.4,97.3z"/>
                    <path class="st0" d="M61.1,97l-0.3,2c0.1,0,0.3,0,0.4,0.1l0.3-2L61.1,97z"/>
                    <path class="st0" d="M38.3,31l0.3-0.3L37,29.1c-0.1,0.1-0.2,0.2-0.3,0.3L38.3,31z"/>
                    <path class="st0" d="M62.2,97.2l-0.2,2c0.1,0,0.3,0,0.4,0l0.2-2L62.2,97.2z"/>
                    <path class="st0" d="M64.5,97.4l-0.1,2c0.1,0,0.3,0,0.4,0l0.1-2L64.5,97.4z"/>
                    <path class="st0" d="M95.8,84.5l-0.3,0.3l1.5,1.4c0.1-0.1,0.2-0.2,0.3-0.3L95.8,84.5z"/>
                    <path class="st0" d="M37.2,84.1l-1.5,1.4c0.1,0.1,0.2,0.2,0.3,0.3l1.5-1.4L37.2,84.1z"/>
                    <path class="st0" d="M65.7,97.4l0,2c0.1,0,0.3,0,0.4,0l0-2L65.7,97.4z"/>
                    <path class="st0" d="M56.6,96.1l-0.5,2c0.1,0,0.3,0.1,0.4,0.1l0.5-2L56.6,96.1z"/>
                    <path class="st0" d="M54.4,95.5l-0.6,1.9c0.1,0,0.3,0.1,0.4,0.1l0.6-1.9L54.4,95.5z"/>
                    <path class="st0" d="M53.3,95.1L52.6,97c0.1,0,0.3,0.1,0.4,0.1l0.7-1.9L53.3,95.1z"/>
                    <path class="st0" d="M55.5,95.8l-0.6,1.9c0.1,0,0.3,0.1,0.4,0.1l0.6-1.9C55.7,95.9,55.6,95.8,55.5,95.8z"/>
                    <path class="st0" d="M60,96.8l-0.3,2c0.1,0,0.3,0,0.4,0.1l0.3-2C60.2,96.9,60.1,96.9,60,96.8z"/>
                    <path class="st0" d="M52.2,94.7l-0.7,1.9c0.1,0,0.2,0.1,0.4,0.1l0.7-1.9L52.2,94.7z"/>
                    <path class="st0" d="M58.8,96.6l-0.4,2c0.1,0,0.3,0,0.4,0.1l0.4-2C59.1,96.7,59,96.7,58.8,96.6z"/>
                    <path class="st0" d="M57.7,96.4l-0.5,2c0.1,0,0.3,0.1,0.4,0.1l0.4-2C57.9,96.4,57.8,96.4,57.7,96.4z"/>
                    <path class="st0" d="M51.1,94.2l-0.8,1.9c0.1,0.1,0.2,0.1,0.4,0.2l0.8-1.9C51.4,94.3,51.2,94.3,51.1,94.2z"/>
                    <path class="st0" d="M47.4,23.9l0.3-0.2l-1.1-2c-0.1,0.1-0.2,0.1-0.4,0.2L47.4,23.9z"/>
                    <path class="st0" d="M35.7,82.4l-1.6,1.3c0.1,0.1,0.2,0.2,0.3,0.3l1.6-1.3L35.7,82.4z"/>
                    <path class="st0" d="M34.4,80.5l-1.7,1.2c0.1,0.1,0.2,0.2,0.2,0.3l1.7-1.2L34.4,80.5z"/>
                    <path class="st0" d="M35.1,81.5l-1.7,1.2c0.1,0.1,0.2,0.2,0.2,0.3l1.6-1.2L35.1,81.5z"/>
                    <path class="st0" d="M33.7,79.6L32,80.7c0.1,0.1,0.1,0.2,0.2,0.3l1.7-1.1L33.7,79.6z"/>
                    <path class="st0" d="M37.8,31.5L36.1,30c-0.1,0.1-0.2,0.2-0.3,0.3l1.7,1.5L37.8,31.5z"/>
                    <path class="st0" d="M33.1,78.6l-1.8,1.1c0.1,0.1,0.1,0.2,0.2,0.3l1.8-1.1L33.1,78.6z"/>
                    <path class="st0" d="M37,32.4l-1.7-1.5c-0.1,0.1-0.2,0.2-0.3,0.3l1.7,1.5L37,32.4z"/>
                    <path class="st0" d="M36.5,83.3l-1.6,1.3c0.1,0.1,0.2,0.2,0.3,0.3l1.6-1.3L36.5,83.3z"/>
                    <path class="st0" d="M34,36.4l0.2-0.3l-1.8-1.3c-0.1,0.1-0.2,0.2-0.2,0.3L34,36.4z"/>
                    <path class="st0" d="M36.2,33.3l-1.7-1.4c-0.1,0.1-0.2,0.2-0.3,0.3l1.7,1.4L36.2,33.3z"/>
                    <path class="st0" d="M30.8,78.6l-0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.3l0.7-0.4H30.8z"/>
                    <path class="st0" d="M35.3,34.5l0.2-0.3l-1.8-1.4c-0.1,0.1-0.2,0.2-0.2,0.3L35.3,34.5z"/>
                    <path class="st0" d="M38,85l-1.5,1.4c0.1,0.1,0.2,0.2,0.3,0.3l1.5-1.4L38,85z"/>
                    <path class="st0" d="M34.6,35.4l0.2-0.3L33,33.8c-0.1,0.1-0.2,0.2-0.2,0.3L34.6,35.4z"/>
                    <path class="st0" d="M32.8,38.3l0.2-0.3L31,36.9c-0.1,0.1-0.1,0.2-0.2,0.4l1.8,1.1H32.8z"/>
                    <path class="st0" d="M38.8,85.8l-1.5,1.4c0.1,0.1,0.2,0.2,0.3,0.3l1.4-1.5L38.8,85.8z"/>
                    <path class="st0" d="M33.3,37.3l0.2-0.3l-1.9-1.2c-0.1,0.1-0.1,0.2-0.2,0.3L33.3,37.3z"/>
                    <path class="st0" d="M50.4,22.4l0.4-0.2l-0.9-2.1c-0.1,0.1-0.2,0.1-0.4,0.2L50.4,22.4z"/>
                    <path class="st0" d="M51.5,21.9l0.3-0.1L51,19.7c-0.1,0.1-0.2,0.1-0.4,0.2L51.5,21.9z"/>
                    <path class="st0" d="M45.4,25.1l0.3-0.2L44.5,23c-0.1,0.1-0.2,0.1-0.3,0.2L45.4,25.1z"/>
                    <path class="st0" d="M49.4,22.9l0.3-0.2l-1-2.1c-0.1,0.1-0.2,0.1-0.4,0.2L49.4,22.9z"/>
                    <path class="st0" d="M53.6,21.1L54,21l-0.7-2.2c-0.1,0-0.3,0.1-0.4,0.1L53.6,21.1z"/>
                    <path class="st0" d="M52.5,21.5l0.4-0.1l-0.8-2.1c-0.1,0-0.3,0.1-0.4,0.1L52.5,21.5z"/>
                    <path class="st0" d="M46.4,24.5l0.3-0.2l-1.2-2c-0.1,0.1-0.2,0.1-0.3,0.2L46.4,24.5z"/>
                    <path class="st0" d="M63,19l-0.2-2.3c-0.1,0-0.3,0-0.4,0l0.2,2.3L63,19z"/>
                    <path class="st0" d="M48.4,23.4l0.3-0.2l-1-2c-0.1,0.1-0.2,0.1-0.4,0.2L48.4,23.4z"/>
                    <path class="st0" d="M64.9,18.9l0.4,0l-0.1-2.3c-0.1,0-0.3,0-0.4,0L64.9,18.9z"/>
                    <path class="st0" d="M61.5,19.2l0.4,0l-0.3-2.3c-0.1,0-0.3,0-0.4,0L61.5,19.2z"/>
                    <path class="st0" d="M60.3,19.4l0.4-0.1L60.3,17c-0.1,0-0.3,0-0.4,0.1L60.3,19.4z"/>
                    <path class="st0" d="M64.1,19L64,16.6c-0.1,0-0.3,0-0.4,0l0.2,2.3L64.1,19z"/>
                    <path class="st0" d="M54.7,20.7l0.4-0.1l-0.7-2.2c-0.1,0-0.3,0.1-0.4,0.1L54.7,20.7z"/>
                    <path class="st0" d="M59.2,19.6l0.4-0.1l-0.4-2.3c-0.1,0-0.3,0-0.4,0.1L59.2,19.6z"/>
                    <path class="st0" d="M58.1,19.8l0.4-0.1l-0.5-2.3c-0.1,0-0.3,0.1-0.4,0.1L58.1,19.8z"/>
                    <path class="st0" d="M55.8,20.4l0.4-0.1l-0.6-2.2c-0.1,0-0.3,0.1-0.4,0.1L55.8,20.4z"/>
                    <path class="st0" d="M56.9,20.1l0.4-0.1l-0.5-2.2c-0.1,0-0.3,0.1-0.4,0.1L56.9,20.1z"/>
                </g>
            </g>
            <path class="st1" d="M111.8,78.5l-9.2,0c0,0-0.4,0.7-0.7,1.1l0,0c-7.3,11.9-20.4,19.8-35.3,19.8c-15,0-28.2-8.1-35.5-20.1
                c-0.1-0.1-0.4-0.5-0.6-0.9l-3.2,0h-6.9c7.9,17.7,25.6,30,46.2,30c20.6,0,38.3-12.4,46.2-30C112.5,78.5,112.1,78.5,111.8,78.5z"/>
            <path class="st1" d="M30.1,38.2l0.6-1l0,0c7.2-12.4,20.6-20.7,35.9-20.7c15.5,0,29,8.6,36.1,21.2l0,0l0.3,0.5h10
                C105.4,20.1,87.5,7.4,66.6,7.4c-20.8,0-38.8,12.7-46.5,30.7c0,0,0,0,0,0.1H30.1z"/>
        </svg>
	</div>
</template>

<script>
    export default {
		name: "badge-logo",
    }

</script>

<style lang="scss" scoped>
    .st0{fill:#f64444;}
    .st1{fill:#373145;}
</style>
