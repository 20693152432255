import AnimationSuite       from "./AnimationSuite";
import StaggerUpAnimation   from "../StaggerUpAnimation";
import StaggerLeftAnimation from "../StaggerLeftAnimation";
import FadeInUpAnimation    from "../FadeInUpAnimation";
import FadeRightInAnimation from "../FadeRightInAnimation";
import FadeLeftInAnimation from "../FadeLeftInAnimation";
import RollUpInAnimation    from "../RollUpInAnimation";
import GrowRightAnimation from "../GrowRightAnimation";


export default class TeamAnimationSuite extends AnimationSuite {

    constructor() {
        super();
        this.animations = [
            new FadeInUpAnimation(".team-list .team-portrait", 0.5, 0.75),
            new RollUpInAnimation(".team-list .team-detail h3 span", 0.5, 1),
            new GrowRightAnimation(".team-list h3 .line", 0.75, 1.15),
            new RollUpInAnimation(".team-list .team-detail .team-subtitle div", 0.5, 1.25),
            new FadeInUpAnimation(".team-list .team-detail p", 0.5, 1.5),
        ];
    }

}