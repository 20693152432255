import Form  from "./Form";
import axios from 'axios';

export default class ContactForm extends Form {

    constructor() {
        super();
        this.name = '';
        this.company = '';
        this.email = '';
        this.phone = '';
        this.message = '';
        this.captcha = '';
    }


    rules() {
        return {
            name   : ['required'],
            email  : ['required', 'email'],
            phone  : ['phone', 'required'],
            message: ['required'],
            captcha: ['required'],
        };
    }

    submit() {
        let data = {
            name   : this.name,
            company: this.company,
            email  : this.email,
            phone  : this.phone,
            message: this.message,
        };

        return axios.post('https://statebuilt.com/api/contact', data).catch(errors => {
            console.log(errors.response.data);});
    }


}