import Animation       from "./Animation";
import { TweenMax }    from "gsap";
import FromToAnimation from "./FromToAnimation";

export default class FadeRightOutAnimation extends FromToAnimation {

    getFrom() {
        return {
            autoAlpha: 1,
            x: 0,
            delay: this.delay,
            ease: Power1.ease,
        }
    }

    getTo() {
        return {
            autoAlpha: 0,
            x: 40,
            delay: this.delay,
            ease: Power1.ease,
        }
    }

}