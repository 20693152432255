<script>
    import ProjectDetail from './ProjectDetail.vue';
    import GodTvProject from '../Objects/Projects/GodTvProject';

    export default {
        extends: ProjectDetail,

        data() {
            return new GodTvProject()
        },

    }

</script>

<style scoped>

</style>