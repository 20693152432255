<template>
	<svg version="1.1" class="icon-youtube" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 38 26" style="enable-background:new 0 0 38 26;" xml:space="preserve">
        <path class="st0" d="M32.8,0.3C29.5,0,19,0,19,0S8.5,0,5.2,0.3C0,0.7,0,7.4,0,12.7c0,7.3,0.8,12.4,4.5,12.9C7.5,26,19,26,19,26
            s11.5,0,14.5-0.4c3.6-0.5,4.5-5.6,4.5-12.9C38,7.4,38,0.7,32.8,0.3z M33.2,23.2c-2.3,0.3-11,0.4-14.2,0.4c-3.2,0-11.9-0.1-14.2-0.4
            c-1.1-0.2-2.4-2.1-2.4-10.6c0-2.5,0-5.3,0.6-7.4C3.6,3,4.5,2.7,5.4,2.7C8.5,2.4,18.6,2.4,19,2.4c0.4,0,10.5,0.1,13.6,0.3
            C33.5,2.7,34.4,3,35,5.3c0.6,2.1,0.6,4.9,0.6,7.4C35.6,21.1,34.3,23.1,33.2,23.2z M24.8,13.1l-9,5.6V7.3L24.8,13.1z"/>
    </svg>
</template>

<script>
    export default {
		name: "icon-youtube",
        data() {
            return {
                
            }
        }
    }
</script>
