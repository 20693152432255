<template>
    <div>
        <div class="main-background-image" :style="{ 'background-image': 'url(' + backgroundImage.getPath() + ')' }">
        </div>
        <article class="about-content">
            <div>
                <h1 class="heading-big" v-html="title"></h1>
                <div class="flex-columns">
                    <div class="about-left">
                        <p>
                            We're a multi-disciplinary team crafting experiences that resonate with today's digital culture.  Based in Austin, Texas, our small team of designers and web developers enjoy building polished websites and web applications that balance simplicity with advanced business dynamics.  
                        </p>
                        <div class="work-button-wrapper">
                            <work-button button-text="Our Work" route-name="work"></work-button>
                        </div>
                    </div>
                    <div class="about-right">
                        <div class="logo-list-wrapper">
                                <ul class="logo-list">
                                <li v-for="(logo, index) in logos" :key="'logo-' + index">
                                    <div>
                                        <img :src="logo.url" :alt="logo.name">
                                    </div>
                                </li>
                            </ul>
                            <div class="line1"></div>
                            <div class="line2"></div>
                        </div>
                    </div>
                </div>
                
            </div>
        </article>
    </div>
</template>

<script>
import Image                   from "../Objects/Image";
import SplitText               from "../Objects/SplitText";
import WorkButton              from "../Components/WorkButton";
import ScrollIndicator         from "../Components/ScrollIndicator";
import { altNav }              from "../Store.js";
import PresentationView        from "./PresentationView";
import AboutAnimationSuite     from "../Objects/Animations/Suites/AboutAnimationSuite";

export default {
    extends: PresentationView,
    components: {
        ScrollIndicator,
        WorkButton
    },
    data() {
        return {
            previousRoute: { name: "home" },
            nextRoute: { name: "services" },
            wrapper: "about",
            backgroundImage: new Image("hero-about.jpg"),
            shared: altNav,
            logos: [
                {
                    url: new Image("/logos/logo-chiron.svg").getPath(),
                    name: "Chiron"
                },
                {
                    url: new Image("/logos/logo-spiceworks.svg").getPath(),
                    name: "Spiceworks"
                },
                {
                    url: new Image("/logos/logo-pledge.svg").getPath(),
                    name: "Pledge Music"
                },
                {
                    url: new Image("/logos/logo-godtv.svg").getPath(),
                    name: "GodTV"
                },
                {
                    url: new Image("/logos/logo-noisetrade.svg").getPath(),
                    name: "Noisetrade"
                },
                {
                    url: new Image("/logos/logo-twotp.svg").getPath(),
                    name: "The Work of the People"
                },
                {
                    url: new Image("/logos/logo-ransomedheart.svg").getPath(),
                    name: "Ransomed Heart"
                },
                {
                    url: new Image("/logos/logo-vnue.svg").getPath(),
                    name: "VNUE"
                },
                {
                    url: new Image("/logos/logo-everfest.svg").getPath(),
                    name: "everfest"
                },
                {
                    url: new Image("/logos/logo-fivestone.svg").getPath(),
                    name: "fivestone"
                },
                {
                    url: new Image("/logos/logo-mars.svg").getPath(),
                    name: "mars"
                },
                {
                    url: new Image("/logos/logo-setfm.svg").getPath(),
                    name: "setfm"
                },
            ]
        }
    },

    computed: {
        title: function() {
            return new SplitText("A digital agency working with brands that love people.").get();
        }
    },

    beforeDestroy() {
        window.recentProject.latest = null;
    },

    mounted() {
        this.$emit("routeChanged", this.backgroundImage.getPath(), this.wrapper);
        new AboutAnimationSuite().animate();
        window.recentProject.latest = null;
        this.setBackButtonData(this.previousRoute, "Back");
    },

}

</script>

<style scoped>

</style>