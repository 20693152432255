import StaggerUpAnimation  from "../StaggerUpAnimation";
import GrowRightAnimation   from "../GrowRightAnimation";
import AnimationSuite      from "./AnimationSuite";
import RollUpInAnimation from "../RollUpInAnimation";
import FadeInUpAnimation from "../FadeInUpAnimation";

export default class HomeAnimationSuite extends AnimationSuite {

    constructor() {
        super();
        this.animations = [
            new GrowRightAnimation(".double-lines", 0.5, 0.75),
            new RollUpInAnimation(".home-content li", 0.5, 1),
            new FadeInUpAnimation(".home-content .work-button", 0.5, 1.35),
        ]
    }

}