<template>
	<svg version="1.1" class="icon-texas" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14 13" style="enable-background:new 0 0 14 13;" xml:space="preserve">
        <path class="st0" d="M0,5.4h3.5V0H7v2.3l2.7,1l2.7-0.1l1,0.3v1.8L14,6.6l-0.5,1.3l-2.6,1.9l-1.2,1.4l0.5,1.8L8,12.2L7,10L5,8.2 L3.7,9.1L2,8.3L1.8,7.1L0.3,5.9L0,5.4z"/>
    </svg>
</template>

<script>
    export default {
		name: "icon-texas",
        data() {
            return {
                
            }
        }

    }
</script>
