import ProjectStateManager from ".././js/Objects/ProjectStateManager";

import CityLifeChurchProject         from ".././js/Objects/Projects/CityLifeChurchProject";
import SerenityProject               from ".././js/Objects/Projects/SerenityProject";
import StartupRevivalProject         from ".././js/Objects/Projects/StartupRevivalProject";
import GodTvProject                  from ".././js/Objects/Projects/GodTvProject";
import SwatchitProject               from ".././js/Objects/Projects/SwatchitProject";
import TheWorkOfThePeopleProject     from ".././js/Objects/Projects/TheWorkOfThePeopleProject";
import AustinBreastfeedingProject    from ".././js/Objects/Projects/AustinBreastfeedingProject";
import FranklinBuildingSupplyProject from ".././js/Objects/Projects/FranklinBuildingSupplyProject";

let altNav = {
    backButton: {
        route: "",
        text: "",
    },
    nextButton: {
        route: {},
        text: "",
        nextHero: null,
    }
};

let beforeWork = {};
let beforeContact = {};

let wipe = {
    up: "",
    right: "",
    down: "",
    left: ""
};

let projects = new ProjectStateManager([
    new CityLifeChurchProject(),
    new SerenityProject(),
    new StartupRevivalProject(),
	new GodTvProject(),
	new SwatchitProject(),
	new TheWorkOfThePeopleProject(),
	new AustinBreastfeedingProject(),
	new FranklinBuildingSupplyProject(),
]);

export { altNav, projects, beforeWork, beforeContact, wipe };