<template>
    <div id="container" :class="wrapper">
        <div id="scrollRouterTrigger">
            <main-header></main-header>
            <router-view @routeChanged="setupView" class="wrap"></router-view>
            <main-footer></main-footer>
        </div>
        <div :class="['wipe-right', 'wipe-' + wipe.right]"></div>
        <div :class="['wipe-left', 'wipe-' + wipe.left]"></div>
        <div :class="['wipe-up', 'wipe-' + wipe.up]"></div>
        <div :class="['wipe-down', 'wipe-' + wipe.down]"></div>
        <scroll-indicator v-if="($route.name !== 'work') && ($route.name !== 'contact')"></scroll-indicator>
    </div>
</template>

<script>
import MainHeader      from "../Components/MainHeader";
import MainFooter      from "../Components/MainFooter";
import Animation       from "../Objects/Animations/Animation";
import { wipe }        from "../Store";
import ScrollIndicator from "../Components/ScrollIndicator";

export default {
    components: {
        ScrollIndicator,
        MainHeader,
        MainFooter,
    },

    data() {
        return {
            wrapper: '',
            backgroundImage: '',
            wipe: wipe,
        }
    },

    methods: {
        setupView: function(backgroundImage, wrapper) {
            this.setwrapper(wrapper);
            this.setBackgroundImage(backgroundImage);
        },

        setwrapper: function(wrapper) {
            this.wrapper = wrapper;
        },

        setBackgroundImage: function(backgroundImage) {
            this.backgroundImage = backgroundImage;
        },

        removeBackgroundImage: function() {
            this.backgroundImage = '';
        },
    },
}
</script>

<style lang="scss">
.scrolling-disabled {
    height: 100%;
    overflow: hidden;
}
#container {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    z-index: 2;
    box-sizing: border-box;
    padding-top: 5rem;
}

.main-background-image {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.wrap {
    margin: 0 auto;
    padding: 0 15vw 160px;
    position: relative;

    @media screen and (max-width: 620px) {
        padding: 0 10vw 100px;
    }
}
</style>