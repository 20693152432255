import Project from "../Project";
import Image from "../Image";
import StartupRevival from "../../Views/StartupRevival";


export default class StartupRevivalProject extends Project {
    constructor() {
        super();
        this.title            = "Startup Revival";
        this.route            = { name: "startup-revival" };
        this.className        = "project-startup-revival";
        this.wrapper          = "project-startup-revival-wrapper";
        this.logo             = new Image("work/startup-revival/SR-logo.svg").getPath();
        this.heroImage        = new Image("work/startup-revival/work-hero-SR-b.jpg").getPath();
        this.scrollScreen     = new Image("work/startup-revival/scroll-screen-SR.jpg").getPath();
        this.comps            = new Image("work/startup-revival/work-comps-SR.jpg").getPath();

        this.role             = [
            "Visual Design",
            "Branding",
            "WordPress Development",
            "Marketing Automation",
        ],
        this.shortDescription = "Design + WordPress Dev";

        this.title1           = "Kingdom Business";
        this.description1     = "Startup Revival is a Christian-based organization with a mission to help and encourage young entrepreneurs interested in starting new businesses and connecting with like-minded community members. They wanted a fresh look and simple tools to publish their content";

        this.title2           = "Simply Different";
        this.description2     = "The website incorporates a custom design featuring large graphics with ample whitespace, projecting a modern aesthetic that resonates with their young audience.  Email automation was employed to encourage sign-ups through an e-book give-away and follow-up drip campaign.";
        this.component        = StartupRevival;
    }
}