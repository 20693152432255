import Image from '../Objects/Image';

export default class ProjectStateManager {
    constructor(projects) {
        this.projects = projects;
        this.selected = null;
        this.scrollToX = null;
    }

    getLength() {
        return this.projects.length;
    }

    all() {
        return this.projects;
    }

    selectFromRouteName(routeName) {
        for(let i = 0; i < this.projects.length; i++) {
            if(routeName === this.projects[i].route.name) {
                this.setSelected(i);
                return;
            }
        }
        this.removeSelected();
    }

    setSelected(index) {
        if(this.selected != null) {
            this.getSelectedProject().deactivate();
        }
        this.selected = index;
        this.projects[index].activate();
    }

    setSelectedToNext() {
        this.setSelected(this.selected + 1);
    }


    activateSelected() {
        this.getSelectedProject().activate();
    }

    removeSelected() {
        if(this.selected != null) {
            this.getSelectedProject().deactivate();
            this.selected = null;
        }
    }

    getSelectedProject() {
        return this.projects[this.selected];
    }

    getSelectedComponent() {
        if(this.selected === null) {
            return;
        }
        return this.projects[this.selected].component;
    }

    getNextProject() {
        if(this.selected + 1 >= this.projects.length) {
            return this.projects[0];
        } else {
            return this.projects[this.selected + 1];
        }
    }

    getPreviousProject() {
        if(this.selected - 1 < 0) {
            return;
        }
        return this.projects[this.selected - 1];
    }

    getScrollToX() {
        if(this.selected === null) {
            return;
        }
        scrollToElem = document.querySelector(".item-" + this.selected);

        return scrollToElem.offsetLeft;
    }

}