<template>
    <div>

        <div class="main-background-image" :style="{ 'background-image': 'url(' + backgroundImage + ')' }"></div>

        <div class="home-content">
            <h1 class="heading-big" v-html="heading"></h1>
            <div class="double-lines"></div>
            <ul>
                <li v-for="(item, index) in homeList" :key="'item-' + index">
                    {{ item }}
                </li> 
            </ul>
            <work-button button-text="Our Work" route-name="work"></work-button>
        </div>
    </div>
</template>

<script>
    import SplitText               from "../Objects/SplitText";
    import Image                   from "../Objects/Image";
    import ScrollIndicator         from "../Components/ScrollIndicator";
    import PresentationView        from "./PresentationView";
    import HeaderInAnimationSuite  from "../Objects/Animations/Suites/HeaderInAnimationSuite";
    import HomeAnimationSuite      from "../Objects/Animations/Suites/HomeAnimationSuite";
    import WipeRightAnimation      from "../Objects/Animations/WipeRightAnimation";
    import WorkButton              from "../Components/WorkButton";
    import { wipe }                from "../Store";
    import { animateBackgroundOut,
             animateContentOut,
             animateHeaderOut,
             animateProjectDetailFooterOut, } from "../Objects/Animations/animation-helpers";

    export default {
        extends   : PresentationView,
        components: {
            WorkButton,
            ScrollIndicator
        },

        data() {
            return {
                wrapper        : "home",
                backgroundImage: new Image('hero-home.jpg'),
                nextRoute      : { name: 'about' },
                homeList: [
                    'Design /',
                    'Development /',
                    'Branding /',
                    'Strategy',
                ]
            }
        },

        computed: {
            heading: function() {
                return new SplitText("We design and build screen experiences that work.").get();
            }
        },

        beforeMount() {
            this.backgroundImage = this.backgroundImage.getPath();
        },

        mounted() {
            this.$emit("routeChanged", '', this.wrapper);
            this.animateElementsIn();
        },

        beforeDestroy() {
            window.recentProject.latest = null;
        },

        methods: {
            animateElementsIn: function() {
                new HomeAnimationSuite().animate();
            },
        }
    }

</script>

<style lang="scss" scoped>

</style>