import Project from "../Project";
import Image from "../Image";
import Serenity from "../../Views/Serenity";

export default class SerenityProject extends Project {
    constructor() {
        super();
        this.title            = "Serenity Research";
        this.route            = { name: "serenity" };
        this.className        = "project-serenity";
        this.wrapper          = "project-serenity-wrapper";


        this.logo             = new Image("work/serenity/logo-serenity.svg").getPath();
        this.heroImage        = new Image("work/serenity/work-hero-serenity.jpg").getPath();
        this.scrollScreen     = new Image("work/serenity/scroll-screen-serenity.jpg").getPath();
        this.comps            = new Image("work/serenity/work-comps-serenity.jpg").getPath();

        this.role             = [
            "Visual Design",
            "Branding",
            "WordPress Development",
            "Marketing Automation",
        ],
        this.shortDescription = "Design + WordPress Dev";

        this.title1           = "Insight Masters";
        this.description1     = "Serenity provides research in a wide range of capacities for their clients. Most of the competing research groups have unremarkable websites that seem to follow the same design patterns. We were able to give Serenity a site that sets them apart from the pack while remaining professional and clear.";

        this.title2           = "Uniquely Modular";
        this.description2     = "We utilized modular elements in our custom WordPress theme that enable Serenity to create new pages that follow the established design patterns. A tiled blog archive makes finding white papers and articles easy while individual posts are clear and concise with few distractions.  Our custom theme is light weight, fast rendering, and solid enough for worry-free updates to the WordPress core.";

        this.component        = Serenity;
    }
}