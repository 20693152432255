<template>
  <div class="work-button">
        <button :href="'/' + routeName" @click.prevent="goToWork">
            <span class="top right vert"></span>
            <span class="top left vert"></span>
            <span class="top right horz"></span>
            <span class="top left horz"></span>
            {{ buttonText}}
            <span class="bot right vert"></span>
            <span class="bot left vert"></span>
            <span class="bot right horz"></span>
            <span class="bot left horz"></span>
        </button>
    </div>
</template>

<script>
import { TweenMax, TimelineLite }         from "gsap";
import { altNav, wipe }       from "../Store.js";
import FadeInAnimation  from "../Objects/Animations/FadeInAnimation";
import FadeOutAnimation from "../Objects/Animations/FadeOutAnimation";
import WipeUpAnimation  from "../Objects/Animations/WipeUpAnimation";
import { resetWipeUp, animateBackgroundOut, animateContentOut, animateHeaderOut, animateProjectDetailFooterOut }  from "../Objects/Animations/animation-helpers";

export default {
    name: "work-button",
    props: ["button-text", "route-name"],

    methods: {
        goToWork: function() {
            wipe.up = this.routeName;
            let vm = this;
            if(this.$route.fullPath.indexOf("work") === -1) {
                animateBackgroundOut();
            }
            animateHeaderOut();
            animateProjectDetailFooterOut();
            animateContentOut(vm.$route.name);
            new WipeUpAnimation(this.changeRoute).animate();
        },

        changeRoute: function(nextRoute) {
            this.$router.push({ name: this.routeName });
        },
    },
}
</script>