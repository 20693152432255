<template>
    <span v-if="error">{{ error }}</span>
</template>

<script>
    export default {
        name: "error",
        props: { error: {} }
    }
</script>

<style scoped>
    span {
        color: #b32b1e;
    }
</style>