import ScaleUpAnimation        from "./ScaleUpAnimation";
import StaggerUpOutAnimation   from "./StaggerUpOutAnimation";
import StaggerUpAnimation      from "./StaggerUpAnimation";
import StaggerWorkItemsAnimation    from "./StaggerWorkItemsAnimation";
import HeaderInAnimationSuite  from "./Suites/HeaderInAnimationSuite";
import ScaleDownAnimation      from "./ScaleDownAnimation";
import HeaderOutAnimationSuite from "./Suites/HeaderOutAnimationSuite";
import FadeDownOutAnimation    from "./FadeDownOutAnimation";
import RollUpInAnimation       from "./RollUpInAnimation";
import RollDownOutAnimation    from "./RollDownOutAnimation";
import { TweenMax, TimelineLite }                from "gsap";

export function animateBackgroundIn() {
    (new ScaleDownAnimation(".main-background-image", 0.5)).animate();
}

export function animateBackgroundOut() {
    (new ScaleUpAnimation(".main-background-image", 0.5)).animate();
}

export function animateContentOut(route) {
    new ScaleUpAnimation("." + route + "-content", 0.25).animate();
    new StaggerUpOutAnimation(".nav-bars li", 0.25, 0.1, 0.05).animate();
}

export function animateHeadingTextIn() {
    (new RollUpInAnimation(".heading-big span > div", 0.75, 0.25)).animate();
}

export function animateHeadingTextOut() {
    (new StaggerUpOutAnimation(".heading-big span", 0.25, 0.05)).animate();
}

export function animateWorkItemsIn() {
    let workSpacer = document.querySelector(".work-spacer");
    const offsetXAmount = workSpacer.clientWidth;


    TweenMax.set(".work-list", {
        overflow: "hidden",
        scrollTo:{
            x: ".item-1",
            offsetX: offsetXAmount,
        }
    });
    (new StaggerWorkItemsAnimation(".work-item", 1, 0.25, 0.1)).animate();
}

export function animateHeaderIn() {
    (new HeaderInAnimationSuite()).animate()
}

export function animateHeaderOut() {
    (new HeaderOutAnimationSuite()).animate();
}

export function animateProjectTitleIn() {
    (new RollUpInAnimation("h1.main-detail-title > span", 0.75)).animate();
}

export function animateProjectTitleOut() {
    (new RollDownOutAnimation("h1.main-detail-title > span", 0.75)).animate();
}

export function animateProjectIn() {
    new RollUpInAnimation(".screen-view", 0.75).animate();
    new StaggerUpAnimation(".description-row > div", 0.25, 0.5, 0.15).animate();
}

export function animateProjectOut() {
    new RollDownOutAnimation(".screen-view", 0.75).animate();
    (new FadeDownOutAnimation(".project-detail", 0.25)).animate();
}

export function animateProjectDetailFooterOut() {
    (new RollDownOutAnimation(".main-footer > span", 0.5)).animate();
}

export function resetWipeRight(elem) {
    let tl = new TimelineLite();

    tl.to(elem, 0.5, {
        autoAlpha: 0,
        ease: Power4.ease,
    }).to(elem, 0, {
        left: "-100vw",
    }).to(elem, 0, {
        autoAlpha: 1, 
    });
}

export function resetWipeLeft(elem) {
    let tl = new TimelineLite();

    tl.to(elem, 0.5, {
        autoAlpha: 0,
        ease: Power4.ease,
    }).to(elem, 0, {
        right: "-100vw",
    }).to(elem, 0, {
        autoAlpha: 1, 
    });
}

export function resetWipeUp(elem) {
    let tl = new TimelineLite();

    tl.to(elem, 0.5, {
        autoAlpha: 0,
        ease: Power4.ease,
    }).to(elem, 0, {
        top: "100vh",
    }).to(elem, 0, {
        autoAlpha: 1, 
    });
}

export function resetWipeDown(elem) {
    let tl = new TimelineLite();

    tl.to(elem, 0.5, {
        autoAlpha: 0,
        ease: Power4.ease,
    }).to(elem, 0, {
        bottom: "100vh",
    }).to(elem, 0, {
        autoAlpha: 1, 
    });
}