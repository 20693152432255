import Project from "../Project";
import Image from "../Image";
import CityLifeChurch from "../../Views/CityLifeChurch";


export default class CityLifeChurchProject extends Project {
    constructor() {
        super();
        this.title            = "City Life Church";
        this.route            = {name: "city-life-church"};
        this.className        = "project-clc";
        this.wrapper          = "project-clc-wrapper";
        this.logo             = new Image("work/clc/work-logo-CLC.svg").getPath();
        this.heroImage        = new Image("work/clc/work-hero-CLC.jpg").getPath();
        this.scrollScreen     = new Image("work/clc/scroll-screen-CLC.jpg").getPath();
        this.comps            = new Image("work/clc/work-comps-CLC.jpg").getPath();

        this.role             = [
            "Visual Design",
            "WordPress Development",
            "Sermon Management",
        ],
        this.shortDescription = "Design + WordPress Dev";

        this.title1           = "Modern Church";
        this.description1     = "City Life wanted a modern and edgy website to resonate with their young congregation. Additionally, they have talented designers on staff who create unique graphics and posters for their programs and series.  The ubiquitious wide, full-width banner simply wouldn’t hold these, often portrait, designs. We were able to create a homepage hero banner that would accept graphics of nearly any aspect ration.";

        this.title2           = "Reader-focused Blog";
        this.description2     = "Since they do a lot of writing, we designed a reader-focused blog that presents content is clean and clear format, reducing clutter and making it easier for visitors to read.  Additionally the site includes utilities for uploading and organizing sermons with a dedicated search.  Events and other reoccuring content are neatly managed with the WordPress backend via our custom theme.";
        this.component        = CityLifeChurch;
    }
}