<template>
    <div class="button-back-wrapper">
        <button id="back-button" @click="onClick">
            <span class="line"></span>
            <span class="button-text">
                <slot name="button-text">{{ this.text }}</slot>
            </span>
        </button>
    </div>
</template>

<script>
    import { altNav }        from "../Store.js";
    import WipeLeftAnimation from "../Objects/Animations/WipeLeftAnimation";

    import { animateBackgroundOut,
             animateContentOut,
             animateHeaderOut,
             animateProjectDetailFooterOut } from "../Objects/Animations/animation-helpers";

    export default {
        name: "button-back",
        data() {
            return altNav.backButton;
        },

        methods: {
            onClick: function() {
                let vm = this;
                if(this.$route.fullPath.indexOf("work/") === -1) {
                    animateContentOut(vm.$route.name);
                    animateHeaderOut();
                    animateProjectDetailFooterOut();
                    if(this.$route.fullPath.indexOf("work") === -1) {
                        animateBackgroundOut();
                    }
                    new WipeLeftAnimation(this.goBack).animate();
                }
            },

            goBack: function() {
                this.$router.push(this.route);
            },
        },


    }
</script>
