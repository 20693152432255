export function setWorkItemWidth() {
    if(getScreenWidth() > 1000) {
        return 700;
    }

    if(getScreenWidth() < 1000 && getScreenWidth() > 700) {
        return 500;
    }

    if(getScreenWidth() < 700) {
        return 250;
    }
}

export function setWorkItemHeight() {
    if(getScreenWidth() > 1000) {
        return 400;
    }

    if(getScreenWidth() < 1000 && getScreenWidth() > 700) {
        return 450;
    }

    if(getScreenWidth() < 700) {
        return 400;
    }
}

function getScreenWidth() {
    return window.innerWidth;
}

export function getScrollWheelForce(e) {
    if(!e) {
        e = window.event;
    }

    let w = e.wheelDelta,
        d = e.detail;

    if(d) {
        if(w) {
            return (
                (
                    w / d / 40 * d
                ) > 0
            ) ? 1 : -1; // Opera
        }
        return -d / 3; // Firefox;
    }

    return w / 120; // IE, Safari & Chrome
}