<template>
    <header class="main-header">
        <button-back></button-back>

        <state-logo></state-logo>

        <button-next v-if="$route.name === 'contact'"></button-next>
        <contact-button v-else></contact-button>
        

        <main-nav></main-nav>
    </header>
</template>

<script>
import MainNav from "./MainNav";
import StateLogo from "./StateLogo";
import ButtonBack from "./ButtonBack";
import ButtonNext from "./ButtonNext";
import ContactButton from "./ContactButton";
import TweenMax from "gsap";

export default {
    name: "main-header",
    components: {
        MainNav,
        StateLogo,
        ButtonBack,
        ButtonNext,
        ContactButton,
    },
    
    data() {
        return {
            
        }
    },

    methods: {
        growToFullScreen: function(elem, timing, delay) {
            TweenMax.to(elem, timing, {
                delay: delay,
                
            });
        },
    },

}

</script>

<style lang="scss" scoped>

</style>