<script>
    import {altNav, wipe, beforeWork, beforeContact} from "../Store";
    import { resetWipeLeft, resetWipeRight, resetWipeUp, resetWipeDown } from "../Objects/Animations/animation-helpers";

    export default {
        name: "BaseView",

        data() {
            return {
                altNav: altNav,
            }
        },

        methods: {
            setWipeClasses: function() {
                if(this.nextRoute) {
                    wipe.right = this.nextRoute.name;
                }
                if(this.previousRoute) {
                    wipe.left = this.previousRoute.name;
                }
                
                if(this.$route.name === "work" && beforeWork.route.name !== null) {
                    wipe.left = beforeWork.route.name;
                } else if(this.$route.name === "work" && beforeWork.route.name === null) {
                    wipe.left = "home";
                }
            },

            setBackButtonData(route, text) {
                this.altNav.backButton.route = route.name ? route : {name: 'home'};
                this.altNav.backButton.text = text ? text : 'Home';
            },
            
            resetWipes: function() {
                resetWipeRight(".wipe-right");
                resetWipeLeft(".wipe-left");
                resetWipeUp(".wipe-up");
                resetWipeDown(".wipe-down");
            },

        },

        mounted() {
            this.resetWipes();
            
            let vm = this;
            setTimeout(this.setWipeClasses, 500);
        }

    }
</script>