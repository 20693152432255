import AnimationSuite        from "./AnimationSuite";
import FadeUpOutAnimation    from "../FadeUpOutAnimation";
import FadeLeftOutAnimation  from "../FadeLeftOutAnimation";
import FadeRightOutAnimation from "../FadeRightOutAnimation";
import RollDownOutAnimation  from "../RollDownInAnimation";

export default class HeaderOutAnimationSuite extends AnimationSuite {

    constructor() {
        super();
        this.animations = [
            new FadeUpOutAnimation(".state-logo", 0.5, 0.1),
            new FadeLeftOutAnimation(".button-back-wrapper", 0.25, 0.1),
            new FadeRightOutAnimation(".button-hire-wrapper", 0.5, 0.1),
            new FadeRightOutAnimation(".button-next-wrapper", 0.5, 0.1),
        ];
    }

}