<template>
    <div class="button-next-wrapper">
        <button id="next-button" @click="goToWork">
            <span class="button-text">
                Work
            </span>
            <span class="line"></span>
        </button>
    </div>
</template>

<script>
import FadeLeftInAnimation               from "../Objects/Animations/FadeLeftInAnimation";
import { wipe }                          from "../Store";
import WipeUpAnimation                   from "../Objects/Animations/WipeUpAnimation";
import { animateHeaderOut,
         resetWipeUp,
         animateProjectDetailFooterOut } from "../Objects/Animations/animation-helpers";

export default {
    name: "button-next",

    data() {
        return {

        }
    },

    methods: {
        goToWork: function() {
            wipe.up = "work";
            animateHeaderOut();
            animateProjectDetailFooterOut();
            new WipeUpAnimation(this.changeRoute).animate();
        },

        changeRoute: function(nextRoute) {
            this.$router.push({ name: "work" });
        },
    },

    mounted() {
            new FadeLeftInAnimation(".button-next-wrapper", 0.5, 0.5).animate();
    },
}
</script>