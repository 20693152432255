import AnimationSuite       from "./AnimationSuite";
import StaggerUpAnimation   from "../StaggerUpAnimation";
import RollUpInAnimation from "../RollUpInAnimation";
import GrowUpAnimation from "../GrowUpAnimation";
import FadeInUpAnimation    from "../FadeInUpAnimation";
import GrowRightAnimation from "../GrowRightAnimation";


export default class ServicesAnimationSuite extends AnimationSuite {

    constructor() {
        super();
        this.animations = [
            new StaggerUpAnimation(".services-text p", 0.5, 0.75, 0.25),
            new FadeInUpAnimation(".work-button", 0.5, 1.15),
            new RollUpInAnimation(".icon-list li div", 0.5, 1.25),
            new RollUpInAnimation(".small-services-list li span", 0.5, 1.25),
            new GrowUpAnimation(".services-list .line1", 0.5, 1.65),
            new GrowRightAnimation(".services-list .line2", 0.5, 1.65),
            new GrowRightAnimation(".services-list .line-solid", 0.5, 1.85),
        ];
    }

}