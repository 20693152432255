import ToAnimation from "./ToAnimation";

export default class HideProjectAnimation extends ToAnimation {

    getTo() {
        return {
            delay          : this.delay,
            opacity        : 0,
            width          : 0,
            scaleX         : 0,
            transformOrigin: "left",
            ease           : Power4.easeInOut
        }
    }

}