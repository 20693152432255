<template>
    <div>
        <div v-touch:pan="handlePan" class="work-list">
            <div id="item-sizer"></div>
            <article
                    @click.prevent="animateSelectedProject(('item-' + index), project.route);"
                    :class="['work-item','item-' + index, {inactive: projects.selected != null && !project.isActive}, project.className]"
                    :style="'width: ' + workItemWidth + 'px; height: ' + workItemHeight + 'px; top: calc(50vh - (' + workItemHeight + 'px / 2));'"
                    v-for="(project, index) in projects.all()"
                    :key="index">
                <div class="background-image" :style="'background-image: url(' + project.heroImage + ');'"></div>
                <header class="project-list-header">
                    <div>
                        <h2>{{project.title}}</h2>
                        <p>
                            {{project.shortDescription}}
                        </p>
                    </div>
                </header>
            </article>

            <div class="work-spacer"></div>
        </div>
    </div>
</template>

<script>
    import { TweenMax }                 from "gsap";
    import ScrollToPlugin               from "gsap/ScrollToPlugin";
    import GrowProjectAnimationSuite    from "../Objects/Animations/Suites/GrowProjectAnimationSuite";
    import WorkListScrollAnimationSuite from "../Objects/Animations/Suites/WorkListScrollAnimationSuite";
    import HideProjectAnimationSuite    from "../Objects/Animations/Suites/HideProjectAnimationSuite";

    import { getScrollWheelForce, setWorkItemHeight, setWorkItemWidth } from "../helpers";
    import { animateHeaderOut, animateProjectDetailFooterOut } from "../Objects/Animations/animation-helpers";

    const plugins = [ ScrollToPlugin ];

    export default {
        name : 'work-list',
        props: ['projects'],

        computed: {
            workItemWidth() {
                return setWorkItemWidth();
            },

            workItemHeight() {
                return setWorkItemHeight();
            },
        },

        data() {
            return {
                allowedToScroll: true    
            }
        },

        methods: {
            setItemLeftPos: function() {
                for(let i = 0; i < this.projects.getLength(); i++) {
                    let leftPosition = (window.innerWidth / 2) - (this.workItemWidth / 2) + (this.workItemWidth * i) + ((this.workItemWidth / 16) * i);

                    TweenMax.set(".item-" + i, {
                        left: leftPosition,
                    });
                }
            },

            handleScroll(e) {
                let deltaY = getScrollWheelForce(e);

                this.scrollTheListBy(deltaY * 120);
            },

            handlePan(direction, touchData) {
                this.scrollTheListBy(touchData.velocityX * 100);
            },

            scrollTheListBy(scrollAmount) {
                if(!this.allowedToScroll) {
                    return;
                }

                TweenMax.to(".work-list", 0.25, {
                    scrollTo: {
                        x: document.querySelector(".work-list").scrollLeft - scrollAmount
                    },
                    ease    : Power1.ease,
                });
            },

            animateSelectedProject(el, route) {
                this.allowedToScroll = false;

                const elem = "." + el;

                for(let i = 0; i < this.projects.getLength(); i++) {
                    if(".item-" + i === elem) {
                        animateHeaderOut();
                        animateProjectDetailFooterOut();
                        new WorkListScrollAnimationSuite(this.getOffsetXOfProject(i), elem).animate();
                        new GrowProjectAnimationSuite(elem).animate();
                        this.delayRouteChange(elem, route);
                    }
                    else if(".item-" + i !== elem) {
                        new HideProjectAnimationSuite(".item-" + i).animate();
                    }
                }
            },

            getOffsetXOfProject(i) {
                if(i === this.projects.getLength() - 1) {
                    return 0;
                }
                return window.innerWidth / 2 - this.workItemWidth / 2;
            },

            delayRouteChange(elem, route) {
                TweenMax.to(elem, 0.5, {
                    delay           : 1.5,
                    onComplete      : this.goToProject,
                    onCompleteParams: [route]
                });
            },

            goToProject(route) {
                window.recentProject.latest = null;
                this.$router.push(route);
            },

        },

        mounted() {
            this.setItemLeftPos();
            document.body.classList.add("disable-vertical-scroll");
            document.addEventListener('mousewheel', this.handleScroll);
            document.addEventListener('DOMMouseScroll', this.handleScroll);
        },

        beforeDestroy() {
            document.body.classList.remove("disable-vertical-scroll");
            document.removeEventListener('mousewheel', this.handleScroll);
            document.removeEventListener('DOMMouseScroll', this.handleScroll);
        }
    }
</script>

<style lang="scss">
    .disable-vertical-scroll {
        overflow: hidden;
    }
</style>